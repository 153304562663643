import React from "react";
import { Card } from "../Card";
import { formatDistanceToNowStrict } from "date-fns";
import _ from "lodash";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Avatar } from "../Avatar";
import { styled } from "../../util/styled";
import { theme } from "../../util/theme";

type SkillsCardProps = {
  id: string;
  tag?: string;
  first_name: string;
  last_name: string;
  country: string;
  rate?: number;
  lastProfileDate?: string;
  skillsMatchedPCT?: number;
  requiredSkills?: string[];
  coreSkills?: string[];
  bonusSkills?: string[];
  reachoutDate?: string;
  reachoutSuccess?: boolean;
  reachoutDetails?: string;
  developerSkills?: {
    years_of_experience: number;
    description: string;
    skill: {
      name: string;
    };
  }[];
  onSelect?: any;
  avatarUrl?: string;
  selected?: boolean;
};

const SkillsCard: React.FC<SkillsCardProps> = ({
  id,
  tag,
  first_name,
  last_name,
  country,
  rate,
  lastProfileDate,
  skillsMatchedPCT,
  requiredSkills,
  coreSkills,
  bonusSkills,
  developerSkills,
  onSelect,
  avatarUrl,
  selected,
  reachoutDate,
  reachoutSuccess = true,
  reachoutDetails
}) => {
  return (
    <Card
      condensed
      fullWidth={false}
      style={{
        position: "relative",
        boxShadow: selected ? "none" : "0 2px 6px #0000002b",
        padding: "0px",
        overflow: "hidden",
        minWidth: "300px"
      }}
    >
      <div style={{ padding: "12px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Avatar name={`${first_name} ${last_name}`} url={avatarUrl} size={50} />
          <div style={{ lineHeight: 1.05, marginLeft: 8, marginTop: 8 }}>
            <Link to={`/developer/${id}`} target="_blank">
              <b>{`${first_name} ${last_name}`}</b>
            </Link>
            <div style={{ fontWeight: "normal", textOverflow: "ellipsis" }}>{country}</div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "6px", marginTop: 0, marginBottom: 0 }}>
          <hr style={{ opacity: 0.25, flex: 1 }} />
          <div style={{ fontVariantCaps: "all-small-caps" }}>Skills</div>
          <hr style={{ opacity: 0.25, flex: 1 }} />
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: 12, justifyContent: "space-around" }}>
          <div>
            <div style={{ textAlign: "center" }}>
              <small style={{ textAlign: "center" }}>Required</small>
            </div>
            {requiredSkills?.map(rs => (
              <small key={rs} style={{ display: "block", fontWeight: "bold" }}>
                {developerSkills?.find((s: { skill: { name: string } }) => s.skill.name === rs) !== undefined ? (
                  <BsCheckCircle fill="green" style={{ marginRight: 4 }} />
                ) : (
                  <BsXCircle fill="red" style={{ marginRight: 4 }} />
                )}
                {rs}
              </small>
            ))}
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <small>Core</small>
            </div>
            {coreSkills?.map(rs => (
              <small key={rs} style={{ display: "block", fontWeight: "bold" }}>
                {developerSkills?.find((s: { skill: { name: string } }) => s.skill.name === rs) !== undefined ? (
                  <BsCheckCircle fill="green" style={{ marginRight: 4 }} />
                ) : (
                  <BsXCircle fill="red" style={{ marginRight: 4 }} />
                )}
                {rs}
              </small>
            ))}
          </div>
          <div>
            <div style={{ textAlign: "center" }}>
              <small>Bonus</small>
            </div>
            {bonusSkills?.map(rs => (
              <small key={rs} style={{ display: "block", fontWeight: "bold" }}>
                {developerSkills?.find((s: { skill: { name: string } }) => s.skill.name === rs) !== undefined ? (
                  <BsCheckCircle fill="green" style={{ marginRight: 4 }} />
                ) : (
                  <BsXCircle fill="red" style={{ marginRight: 4 }} />
                )}
                {rs}
              </small>
            ))}
          </div>
        </div>
      </div>
      <div style={{ padding: "8px", backgroundColor: theme.colors.cardHeader, display: "flex" }}>
        <div style={{ textAlign: "center", lineHeight: "1.2", margin: "auto" }}>
          <b>{skillsMatchedPCT || "0"}%</b>
          <div style={{ fontSize: 16, fontVariantCaps: "all-small-caps" }}>Match</div>
        </div>

        <div style={{ textAlign: "center", lineHeight: "1.2", margin: "auto" }}>
          <b>${rate || "0"}</b>
          <div style={{ fontSize: 16, fontVariantCaps: "all-small-caps" }}>Rate</div>
        </div>
        <div style={{ textAlign: "center", lineHeight: "1.2", margin: "auto" }}>
          <b>
            {lastProfileDate
              ? formatDistanceToNowStrict(new Date(lastProfileDate), { addSuffix: false, unit: "day" })
              : "-"}
          </b>
          <div style={{ fontSize: 16, fontVariantCaps: "all-small-caps" }}>Last Profile</div>
        </div>
        <div
          title={reachoutDetails ? JSON.stringify(reachoutDetails) : ""}
          style={{ textAlign: "center", lineHeight: "1.2", margin: "auto" }}
        >
          <b style={{ color: reachoutDate && reachoutSuccess === false ? "red" : "inherit" }}>
            {reachoutDate ? formatDistanceToNowStrict(new Date(reachoutDate)) : "-"}
          </b>
          <div style={{ fontSize: 16, fontVariantCaps: "all-small-caps" }}>Reachout</div>
        </div>
      </div>
      <div style={{ position: "absolute", top: 2, right: 8 }}>
        <input type="checkbox" onChange={onSelect} checked={selected} style={{ width: "16px", height: "16px" }} />
      </div>
      {tag && <div style={{ position: "absolute", top: 2, left: 8 }}>{tag}</div>}
    </Card>
  );
};

export default SkillsCard;
