import React, { useState } from "react";
import { styled } from "../util/styled";
import { Button } from "./Button";

const HintList = styled("dl")`
  background-color: #f7f7f7;
  padding: 20px;
  max-width: 500px;
  font-size: 0.9rem;

  dt {
    font-weight: bold;
    margin-top: 10px;
  }

  dd {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  code {
    background-color: #e0e0e0;
    padding: 2px 5px;
    border-radius: 3px;
    font-family: "Courier New", monospace;
  }
`;

const ResumeSearchHints = ({ displayHint }: { displayHint: boolean }) => {
  return (
    <>
      {displayHint && (
        <HintList>
          <dl style={{ marginBlockStart: 0 }}>
            <dt>Default Behavior</dt>
            <dd>
              Spaces between words are interpreted as an AND operator. Searching for <code>apple banana</code> will find
              entries containing both words.
            </dd>

            <dt>OR Operator</dt>
            <dd>
              Use the word <code>OR</code> to search for entries that contain either word. For example,{" "}
              <code>apple OR banana</code>.
            </dd>

            <dt>Negation</dt>
            <dd>
              Use a dash <code>-</code> to exclude words from your search. For example, <code>apple -banana</code> will
              find entries containing 'apple' but not 'banana'.
            </dd>

            <dt>Phrases</dt>
            <dd>
              Enclose words in double quotes to search for an exact phrase. For example, <code>"apple pie"</code>.
            </dd>

            <dt>Special Characters</dt>
            <dd>
              Most special characters are ignored in the search. To search for a phrase that includes special
              characters, enclose the phrase in quotes. For example, <code>"C++ developer"</code>.
            </dd>

            <dt>Combining Operators</dt>
            <dd>
              You can combine the OR and negation operators for advanced searches. For example,{" "}
              <code>"apple pie" -banana OR cherry</code> will find entries containing 'apple pie' or 'cherry', but not
              'banana'.
            </dd>
          </dl>
        </HintList>
      )}
    </>
  );
};

export default ResumeSearchHints;
