import React, { useState, useEffect, FC, CSSProperties } from "react";

import { Container } from "./SuspenseFallback.styled";
import { LogoPulseLoader } from "./LogoPulseLoader";

export const SuspenseFallback: FC<{ style?: CSSProperties }> = props => {
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShouldShow(true), 600);
    return () => clearTimeout(timeout);
  }, []);

  return shouldShow ? (
    <Container {...props}>
      <LogoPulseLoader />
    </Container>
  ) : null;
};
