import { styled } from "../util/styled";

const sidebarWidth = 200;

export const Container = styled("div", {
  shouldForwardProp: prop => prop !== "sidebar"
})<{ sidebar: boolean }>`
  display: grid;
  grid-template-columns: ${sidebarWidth}px calc(100% - ${sidebarWidth}px);
  grid-template-rows: ${({ theme }) => theme.headerHeight}px ${({ theme }) => `calc(100vh - ${theme.headerHeight}px)`};
  grid-template-areas: ${({ sidebar }) =>
    sidebar
      ? `"sidenav header"
  "sidenav content"`
      : `"header header"
  "content content"`};
  height: 100vh;
  color: ${({ theme }) => theme.colors.text};
`;
