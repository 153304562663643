import { styled } from "../util/styled";

export const PopoverContent = styled.div<{ condensed?: boolean }>(({ theme, condensed }) => ({
  position: "relative",
  background: theme.colors.white,
  padding: condensed ? 0 : theme.space,
  borderRadius: 4,
  boxShadow: "0 7px 12px #0006",
  marginTop: theme.space / 2,
  zIndex: 2,
  maxHeight: "50vh",
  overflow: "auto",

  "::before": {
    content: '""',
    borderWidth: 8,
    borderStyle: "solid",
    borderColor: "transparent",
    borderBottomColor: theme.colors.white,
    top: condensed ? 0 : -theme.space / 2,
    left: condensed ? 0 : theme.space / 2,
    position: "absolute",
    width: 0,
    height: 0
  }
}));
