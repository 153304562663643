import { ApolloError } from "@apollo/client";
import React from "react";
import { Toast } from "./Toast";
import { ServerError } from "apollo-link-http-common";

const JWTExpiredMessage = "JWTExpired";

function ErrorToast({ error }: { error: ApolloError | Error | undefined }) {
  // If there is no error, we don't want to show anything
  if (!error) return null;

  /**
   * The reason why we are doing this is because we're silently refreshing the access token
   * so, we don't want to show the error message to the user.
   */
  if ("graphQLErrors" in error) {
    const networkErrorMessage = (error?.networkError as ServerError)?.result?.[0]?.errors?.[0]?.message;
    if (error.message.includes(JWTExpiredMessage) || networkErrorMessage?.includes(JWTExpiredMessage)) return null;
  }

  return <Toast type="danger">{error?.message}</Toast>;
}

export default ErrorToast;
