import React, { FC } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import ReactPaginate from "react-paginate";

type Props = {
  pageCount: number;
  onPage: (data: { selected: number }) => void;
  initialPage?: number;
};

export const Paginator: FC<Props> = ({ onPage, pageCount, initialPage = 1 }) => (
  <ReactPaginate
    previousLabel={<FiArrowLeft />}
    nextLabel={<FiArrowRight />}
    breakLabel="..."
    pageCount={pageCount}
    marginPagesDisplayed={1}
    pageRangeDisplayed={4}
    onPageChange={onPage}
    containerClassName="pagination"
    activeClassName="active"
    initialPage={initialPage}
  />
);
