import React, { FC } from "react";

export const Logo: FC = props => (
  <svg width="60" height="60" fill="none" {...props}>
    <circle cx="30" cy="30" r="30" fill="#fff"></circle>
    <path
      d="M30 0C13.42 0 0 13.442 0 30s13.442 30 30 30 30-13.442 30-30C60 13.42 46.558 0 30 0zm15.626 24.7c1.023 0 1.864.842 1.864 1.866a1.873 1.873 0 01-1.864 1.865 1.873 1.873 0 01-1.866-1.865c0-1.024.842-1.865 1.866-1.865zm-18.97 7.87h-.818v5.96h-1.342l-.978-2.07c-1.092 1.455-3.048 2.433-5.163 2.433-4.276 0-8.143-3.23-8.143-8.939 0-5.686 4.48-8.938 8.802-8.938 4.572 0 6.938 3.07 6.938 5.618 0 .978-.797 1.774-1.775 1.774a1.776 1.776 0 01-1.774-1.774 1.78 1.78 0 011.615-1.774c-.364-1.479-2.547-2.661-4.822-2.661-3.639 0-5.618 3.047-5.618 7.733 0 4.549 2.07 7.642 4.845 7.642 2.297 0 4.413-1.365 4.413-3.389v-1.638h-2.138v-1.182h5.959v1.205zm10.622 6.369c-2.82 0-2.82-1.66-4.662-1.66-1.615 0-2.934 1.273-2.934 1.273H27.93c.41-3.366 3.025-4.344 6.642-7.165 1.546-1.205 3.002-2.82 3.002-5.23 0-1.297-.546-3.958-3.457-3.958-2.024 0-3.617 1.091-3.867 2.479a1.872 1.872 0 011.774 1.865 1.874 1.874 0 01-1.865 1.865 1.873 1.873 0 01-1.865-1.865c0-2.343 2.138-5.527 5.982-5.527 4.253 0 6.391 2.775 6.391 5.231 0 6.391-8.847 7.597-9.826 10.212 0 0 1.297-.636 2.457-.636 1.638 0 2.183 1.023 3.707 1.023 1.274 0 2.343-.591 2.343-1.91h2.16c0 1.614-.91 4.003-4.23 4.003zm11.827-.387H43.17v-1.183h1.706v-6.368h-1.706v-1.138l4.23.023v7.506h1.706v1.16z"
      fill="#FF2B2B"
    ></path>
  </svg>
);
