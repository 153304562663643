import React, { FC, ReactNode } from "react";

import { Container, IconContainer, LabelContainer } from "./SidenavItem.styled";

type SidenavItemProps = {
  active?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
};

export const SidenavItem: FC<SidenavItemProps> = ({ children, onClick, icon, active }) => {
  return (
    <Container
      active={Boolean(active)}
      onClick={onClick}
      onKeyDown={e => {
        if (onClick && [" ", "Enter"].includes(e.key)) {
          onClick();
        }
      }}
    >
      <IconContainer>{icon}</IconContainer>
      <LabelContainer>{children}</LabelContainer>
    </Container>
  );
};
