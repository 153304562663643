import { MenuButton, MenuButtonProps } from "@reach/menu-button";

import { styled } from "../util/styled";

export const Container = styled(MenuButton, { shouldForwardProp: p => !["hasAction", "condensed"].includes(p) })<
  { hasAction: boolean; condensed: boolean } & MenuButtonProps
>`
  appearance: none;
  display: inline-flex;
  align-items: center;
  border: 0;
  padding: ${({ theme, condensed }) => theme.space / (condensed ? 4 : 2)}px;
  border-radius: 2px;
  background-color: #d8e3ee;
  font-size: ${({ condensed }) => (condensed ? 0.7 : 0.9)}rem;
  font-weight: 600;
  cursor: pointer;

  ${({ onClick }) =>
    onClick
      ? `
  :hover {
    background-color: #b3ceef;
  }`
      : ""}
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid #0003;
  padding-left: ${({ theme }) => theme.space / 2}px;
  margin-left: ${({ theme }) => theme.space / 2}px;
`;
