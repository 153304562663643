import React, { Component } from "react";
import { Toast } from "./Toast";

interface ErrorBoundaryState {
  error: Error | null;
}

export class ErrorBoundary extends Component<{}, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);

    return { error: error };
  }

  render() {
    if (this.state.error) {
      return (
        <Toast type="danger" onDismiss={() => window.location.reload()}>
          {this.state.error}. Closing this will reload the page and try again.
        </Toast>
      );
    }

    return <>{this.props.children}</>;
  }
}
