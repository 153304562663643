import React, { FC, ReactNode, useRef } from "react";
import { Menu, MenuPopover } from "@reach/menu-button";

import { Container, IconContainer } from "./Chip.styled";
import { PopoverContent } from "./PopoverContent";

type ChipProps = {
  label: ReactNode;
  condensed?: boolean;
  action?: {
    icon?: ReactNode;
    onClick?: () => void;
  };
  showOnHover?: boolean;
  style?: React.CSSProperties;
};

export const Chip: FC<ChipProps> = ({ label, children, condensed, action, style, showOnHover }) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleOnMouseOver = () => {
    if (!showOnHover || !popoverRef.current) return;
    popoverRef.current.hidden = false;
  };

  const handleOnMouseOut = () => {
    if (!showOnHover || !popoverRef.current) return;
    popoverRef.current.hidden = true;
  };

  return (
    <Menu>
      <Container
        style={style}
        condensed={Boolean(condensed)}
        onClick={action?.onClick}
        hasAction={Boolean(action?.icon)}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
      >
        {label}
        {action?.icon && <IconContainer>{action.icon}</IconContainer>}
      </Container>
      {children && (
        <MenuPopover ref={popoverRef}>
          <PopoverContent>{children}</PopoverContent>
        </MenuPopover>
      )}
    </Menu>
  );
};
