import { styled } from "../util/styled";

export const AddClientForm = styled.form`
  width: 100%;
  display: grid;
  grid-template-areas:
    "name name"
    "email email"
    "stage stage"
    "actions actions";
  gap: ${({ theme }) => theme.space * 1.5}px;
  grid-template-columns: 1fr 1fr;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
  align-self: center;
`;
