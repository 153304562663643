import { useState } from "react";

export type Column<DataObject> = Partial<
  Record<keyof DataObject, "asc_nulls_last" | "desc_nulls_last" | "asc_nulls_first" | "desc_nulls_first" | null>
> | null;

export const useColumnSort = <T extends object>(defaultSort?: Column<T>) => {
  const [sortedColumn, setSortedColumn] = useState<Column<T>>(defaultSort || null);

  const handleSort = (columnName: keyof T) => {
    const direction = sortedColumn
      ? sortedColumn[columnName] === "asc_nulls_last"
        ? "desc_nulls_last"
        : "asc_nulls_last"
      : "asc_nulls_last";
    setSortedColumn({ [columnName]: direction } as Column<T>);
  };

  return { sortedColumn, handleSort };
};
