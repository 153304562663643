import { styled } from "../util/styled";

export const SidenavHeader = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.headerHeight}px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff3;
  border-top: 1px solid #fff3;
  padding: ${({ theme }) => theme.space / 2}px;
  font-weight: bold;
  font-size: 0.8rem;
`;
