import React, { ReactNode, FC, MouseEvent, CSSProperties, useCallback } from "react";
import { Menu, MenuList } from "@reach/menu-button";

import { Container, MenuButton, TextContainer } from "./Button.styled";
import { Theme } from "../util/theme";
import { MenuOption } from "./Option";
import { PopoverContent } from "./PopoverContent";
import { Spinner } from "./Spinner";

export type BaseButtonProps = {
  type: "regular" | "menu" | "text";
  htmlButtonType?: "button" | "submit" | "reset";
  color?: keyof Theme["colors"];
  disabled?: boolean;
  outline?: boolean;
  loading?: boolean;
  style?: CSSProperties;
  fullWidth?: boolean;
};

type MenuButtonProps = BaseButtonProps & {
  type: "menu";
  items: { label: ReactNode; onClick: () => void }[];
  onClick?: never;
};

type RegularButtonProps = BaseButtonProps & {
  type: "regular" | "text";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  items?: never;
};

type ButtonProps = MenuButtonProps | RegularButtonProps;

const spinnerSize = 16;

export const Button: FC<ButtonProps> = ({ htmlButtonType: buttonType, type, outline, loading, onClick, ...props }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  if (type === "menu") {
    const { items, ...rest } = props;
    return (
      <Menu>
        <MenuButton disabled={loading} outline={Boolean(outline)} onClick={handleClick} {...rest}>
          {loading ? <Spinner size={spinnerSize} /> : props.children}
        </MenuButton>
        <MenuList>
          <PopoverContent condensed>
            {items &&
              items.map((item, i) => (
                <MenuOption
                  key={i}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onSelect={item.onClick}
                >
                  {item.label}
                </MenuOption>
              ))}
          </PopoverContent>
        </MenuList>
      </Menu>
    );
  }

  if (type === "text") {
    return loading ? <Spinner size={spinnerSize} /> : <TextContainer onClick={handleClick} {...props} />;
  }

  return (
    <Container type={buttonType} disabled={loading} outline={Boolean(outline)} onClick={handleClick} {...props}>
      {loading ? <Spinner size={spinnerSize} /> : props.children}
    </Container>
  );
};
