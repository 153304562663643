import { createContext, useContext } from "react";

import { Theme } from "../util/theme";

export type PushMessageOptions = { text: string; type?: keyof Theme["colors"]; onClick?: () => void };

export const ToastContext = createContext({
  pushMessage: (options: PushMessageOptions) => {}
});

export const useToastAlert = () => {
  const { pushMessage } = useContext(ToastContext);
  const alertSuccess = (text: string) => pushMessage({ type: "success", text });
  const alertFailure = (text: string) => pushMessage({ type: "warning", text });
  return {
    alertSuccess,
    alertFailure
  };
};
