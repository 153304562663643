import { useState, useEffect } from "react";

export const useSuspenseLoading = (loading: boolean, delay: number = 2000) => {
  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  useEffect(() => {
    if (loading) {
      const loadingTimeout = setTimeout(
        () => setShouldShowLoading(true),
        delay /* show loading if it takes too long, other ways just update smoothly */
      );
      return () => clearTimeout(loadingTimeout);
    } else if (!loading && shouldShowLoading) {
      setShouldShowLoading(false);
    }
  }, [loading, shouldShowLoading, delay]);

  return shouldShowLoading;
};
