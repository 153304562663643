import { styled } from "../util/styled";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Link = styled("button", { shouldForwardProp: p => p !== "active" })<{ active?: boolean }>`
  appearance: none;
  background-color: transparent;
  border: 0;
  font-size: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space / 2}px;
  border-radius: 2px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  color: ${({ active, theme }) => (active ? theme.colors.textH2 : theme.colors.text)};

  :hover {
    background-color: ${({ theme }) => theme.colors.lightBorder};
    color: ${({ theme }) => theme.colors.textH2};
  }
`;

export const Separator = styled.div`
  margin: 0 4px;
`;
