import { styled } from "../util/styled";

export const Header = styled.header`
  width: 100%;
  height: 100%;
  background-color: white;
  grid-area: header;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
  padding: ${({ theme }) => theme.space}px;
`;
