import React, { FC } from "react";

import { DismissButton, Container } from "./Toast.styled";
import { Theme } from "../util/theme";

type ToastProps = {
  onClick?: (content: string) => void;
  onDismiss?: () => void;
  type?: keyof Theme["colors"];
};

export const Toast: FC<ToastProps> = ({ children, onClick, onDismiss, type }) => (
  <Container
    color={type}
    onClick={() => {
      if (onClick) {
        onClick(JSON.stringify(children));
      }
    }}
  >
    {onDismiss && (
      <DismissButton
        onClick={e => {
          e.stopPropagation();
          onDismiss();
        }}
      />
    )}
    {children}
  </Container>
);
