import React, { CSSProperties, FC, MouseEvent } from "react";

import { Container, Image, ImageContainer, LabelContainer, ContentContainer, HoverOverlay } from "./Avatar.styled";

export type AvatarProps = {
  size?: number;
  url?: string;
  name: string;
  showName?: boolean;
  hoverOverlay?: React.ReactNode;
  onClick?: (e: MouseEvent) => void;
  style?: CSSProperties;
};

export const Avatar: FC<AvatarProps> = ({ size = 32, name, url, children, showName, hoverOverlay, ...props }) => {
  const [hoverOverlayVisible, setHoverOverlayVisible] = React.useState(false);
  return (
    <Container
      {...props}
      hasName={Boolean(showName)}
      blurHover={!!hoverOverlay}
      size={size}
      onMouseEnter={() => {
        hoverOverlay && setHoverOverlayVisible(true);
      }}
      onMouseLeave={() => {
        hoverOverlay && setHoverOverlayVisible(false);
      }}
    >
      <ImageContainer url={url} name={name} size={size}>
        {url && <Image alt={name} src={url} />}
        {name[0]}
        {hoverOverlay && hoverOverlayVisible && (
          <HoverOverlay visible={hoverOverlayVisible}>{hoverOverlay}</HoverOverlay>
        )}
      </ImageContainer>
      {showName && (
        <LabelContainer>
          {name}
          <ContentContainer>{children}</ContentContainer>
        </LabelContainer>
      )}
    </Container>
  );
};

Avatar.displayName = "Avatar";
