// Maps strings deterministally to the same color. Avoids similar strings ending up with the same color.
export const mapStringToColors = (colors: string[]) => (str: string) =>
  colors[hash32FNV1aUTF(String(str)) % colors.length];

const hash32FNV1aUTF = (str: string) => {
  let c;
  let i;
  const l = str.length;
  let t0 = 0;
  let v0 = 0x9dc5;
  let t1 = 0;
  let v1 = 0x811c;

  for (i = 0; i < l; i = i + 1) {
    c = str.charCodeAt(i);
    v0 ^= c;
    t0 = v0 * 403;
    t1 = v1 * 403;
    t1 += v0 << 8;
    v1 = (t1 + (t0 >>> 16)) & 65535;
    v0 = t0 & 65535;
  }

  return ((v1 << 16) >>> 0) + v0;
};
