import React, { FC, useState, useEffect, useCallback } from "react";

import { PushMessageOptions, ToastContext } from "../contexts/Toast";
import { Toast } from "../components/Toast";

export const ToastProvider: FC = ({ children }) => {
  const [message, setMessage] = useState<PushMessageOptions>({ text: "", onClick: () => {} });

  useEffect(() => {
    if (Boolean(message.text)) {
      const timeout = setTimeout(() => setMessage({ text: "" }), 5000);
      return () => clearTimeout(timeout);
    }
  }, [message]);

  const pushMessage = useCallback((message: PushMessageOptions) => {
    // We want to ignore JWTExpired errors in toast messages
    if (message.text.includes("JWTExpired")) return;
    setMessage(message);
  }, []);

  return (
    <>
      {message.text && (
        <Toast onClick={message.onClick} type={message.type}>
          {message.text}
        </Toast>
      )}
      <ToastContext.Provider value={{ pushMessage }}>{children}</ToastContext.Provider>
    </>
  );
};
