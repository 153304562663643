import { createContext, Context } from "react";

export const CurrentUserContext = createContext({
  id: "",
  displayName: "",
  username: "",
  role: ""
});

type extractGeneric<Type> = Type extends Context<infer X> ? X : never;
export type CurrentUser = extractGeneric<typeof CurrentUserContext>;
