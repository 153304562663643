const defaultOptions = {
  insecure: false
};

export const addProtocol = (url: string, options = defaultOptions) => {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    return `http${options.insecure ? "" : "s"}://` + url;
  }
  return url;
};
