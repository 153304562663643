import React from "react";

const modals = ["Add Developer", "Search Developers", "Add Client", "Edit Client"] as const;

type ModalName = typeof modals[number];

type ModalsContextValue = {
  openModal(modalName: ModalName): void;
  closeModal(modalName: ModalName): void;
  checkIfOpen(modalName: ModalName): boolean;
};

type ModalState = Record<ModalName, boolean>;

export const ModalsContext = React.createContext<ModalsContextValue>({
  openModal: () => {},
  closeModal: () => {},
  checkIfOpen: () => false
});

const ModalsReducer: React.Reducer<ModalState, Partial<ModalState>> = (prev, next) => ({
  ...prev,
  ...next
});

const initialValues = modals.reduce((obj: any, item) => {
  obj[item] = false;
  return obj;
}, {});

export const ModalsProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(ModalsReducer, initialValues as any);
  const openModal = (modalName: ModalName) => dispatch({ [modalName]: true });
  const closeModal = (modalName: ModalName) => dispatch({ [modalName]: false });
  const checkIfOpen = (modalName: ModalName) => state[modalName];

  return (
    <ModalsContext.Provider
      value={{
        openModal,
        closeModal,
        checkIfOpen
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
