import { ComboboxInputProps, Combobox, ComboboxPopover, ComboboxList } from "@reach/combobox";
import React, { InputHTMLAttributes, ReactNode, useRef } from "react";

import { ComboboxInput, InputElement, InputContainer, IconContainer } from "./Input.styled";
import { Label } from "./Label";
import { Spinner } from "./Spinner";

type BaseInputProps<T> = {
  typeOfInput: "combo" | "regular";
  fullWidth?: boolean;
  label?: string;
  loading?: boolean;
  value: string | undefined;
} & Omit<Partial<InputHTMLAttributes<T>>, "value">;

type ComboInputProps<T, U> = {
  typeOfInput: "combo";
  onChooseItem: (value: string) => void;
  items: U[];
  renderItem: (item: U) => ReactNode;
} & Omit<ComboboxInputProps, "onSelect" | "value"> &
  BaseInputProps<T>;

type RegularInputProps<T> = {
  typeOfInput: "regular";
  onChooseItem?: never;
  items?: never;
  renderItem?: never;
} & BaseInputProps<T>;

export type InputProps<T, U = any> = ComboInputProps<T, U> | RegularInputProps<T>;

export function Input<T>({ typeOfInput, style, fullWidth, label, loading, ...props }: InputProps<HTMLInputElement, T>) {
  const comboboxRef = useRef<HTMLInputElement>(null);
  if (typeOfInput === "combo") {
    const { onChooseItem, items, renderItem, ...rest } = props;
    return (
      <Combobox
        style={style}
        onSelect={i => {
          if (onChooseItem) {
            onChooseItem(i);
            /* Gotta run on the next frame bc we are still focused */
            requestAnimationFrame(() => {
              comboboxRef.current?.blur();
            });
          }
        }}
      >
        <Label hasText={Boolean(label)} fullWidth={Boolean(fullWidth)}>
          {label}
          <InputContainer hasIcon={Boolean(loading)}>
            <ComboboxInput ref={comboboxRef} autocomplete={false} autoComplete="off" {...rest} />
            {loading && (
              <IconContainer>
                <Spinner />
              </IconContainer>
            )}
          </InputContainer>
        </Label>
        {items && items.length > 0 && renderItem && (
          <ComboboxPopover>
            <ComboboxList>{items.map(renderItem)}</ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    );
  }

  return (
    <Label hasText={Boolean(label)} style={style} fullWidth={Boolean(fullWidth)}>
      {label}
      <InputContainer hasIcon={Boolean(loading)}>
        <InputElement aria-label={label} {...props} />
        {loading && (
          <IconContainer>
            <Spinner />
          </IconContainer>
        )}
      </InputContainer>
    </Label>
  );
}
