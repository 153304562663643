import React, { useContext, useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { ListContainer } from "../ListPageContent";
import { SuspenseFallback } from "../SuspenseFallback";
import getCloudinaryUrl from "../../util/getCloudinaryUrl";
import { getAvatarUrl } from "../../util/getAvatarUrl";
import { Button } from "../Button";
import { format } from "date-fns";
import { allSearchDevs, getAllActiveJobs_jobs_job_profiles, getAllActiveJobs_jobs_job_skills } from "../../queries";
import { countries } from "countries-list";
import _ from "lodash";
import SkillsCard from "./SkillsCard";
import { ToastContext } from "../../contexts/Toast";

type SkillsSearchResultsProps = {
  jobId: string;
  jobTitle: string;
  job_skills: getAllActiveJobs_jobs_job_skills[];
  job_profiles: getAllActiveJobs_jobs_job_profiles[];
  location: any;
};

const SkillsSearchResults: React.FC<SkillsSearchResultsProps> = ({
  jobId,
  jobTitle,
  job_skills,
  job_profiles,
  location
}) => {
  const defaultSlackMessage = `It looks like you're a match!\n\nYou have the skills associated with the ${jobTitle} role.\n\nPlease apply ASAP at https://portal.g2i.co/jobs#${jobId}`;
  const [matchedDevs, setMatchedDevs] = useState<any[]>([]);
  const [matchedDevsLoading, setMatchedDevsLoading] = useState<boolean>(true);
  const [maxResults, setMaxResults] = useState<number>(50);
  const [slackList, updateSlackList] = useState<any>([]);
  const [bulkSelectCount, setBulkSelectCount] = useState<number>(0);
  const [slackMessage, setSlackMessage] = useState<any>({
    value: defaultSlackMessage
  });
  const { pushMessage } = useContext(ToastContext);

  let requiredSkills: string[] = [];
  const coreSkills: string[] = [];
  const bonusSkills: string[] = [];

  job_skills.forEach(skill => {
    if (skill.job_skill_type === "REQUIRED") {
      requiredSkills.push(skill.skill.name);
    } else if (skill.job_skill_type === "CORE") {
      coreSkills.push(skill.skill.name);
    } else {
      bonusSkills.push(skill.skill.name);
    }
  });

  const defaultLocation = ["US", "CA", "BR"];
  const { data: allSearchDevs } = useQuery<allSearchDevs>(
    gql`
      query allSearchDevs($countries: [String!] = []) {
        developers(
          where: { deleted_at: { _is_null: true }, stage: { _eq: MEMBER }, country: { _in: $countries } }
        ) {
          id
          first_name
          last_name
          email
          stage
          cost_rate
          country
          created_at
          url_avatar
          url_github
          tos_signed_on
          skills {
            skill {
              name
            }
            years_of_experience
          }
          developer_assessments(where: { completed_at: { _is_null: false }, deleted_at: { _is_null: true } }) {
            assessment {
              name
            }
            result
            total_score
          }
          job_profiles(order_by: { created_at: desc }) {
            created_at
            cost_rate
          }
          job_messages(where: {job_id: { _eq: "${jobId}" } }, order_by: {created_at: desc}){
            created_at
            delivery_method
            success
            metadata
          }
        }
      }
    `,
    { variables: { countries: location && location.length > 0 ? location : defaultLocation }, pollInterval: 10000 }
  );

  useEffect(() => {
    if (allSearchDevs && allSearchDevs.developers) {
      const appliedIds = job_profiles.map(d => d.developer_id);
      const notApplied = allSearchDevs.developers.filter(d => !appliedIds.includes(d.id));
      const perfectScore = requiredSkills.length * 1 + coreSkills.length * 0.5 + bonusSkills.length * 0.25;
      const matchScoreDevs = _.reverse(
        _.sortBy(
          notApplied.map((developer: { skills: { skill: { name: string } }[] }) => {
            let matchScore = 0;
            developer.skills.forEach((skill: { skill: { name: string } }) => {
              if (requiredSkills.includes(skill.skill.name)) matchScore += 1;
              if (coreSkills.includes(skill.skill.name)) matchScore += 0.5;
              if (bonusSkills.includes(skill.skill.name)) matchScore += 0.25;
            });
            return {
              ...developer,
              skillsMatch: Math.round((matchScore / perfectScore) * 100),
              lastAppDate: getLastAppDate(developer)
            };
          }),
          ["skillsMatch", "lastAppDate"]
        )
      );
      setMatchedDevs(matchScoreDevs);
      setMatchedDevsLoading(false);
      console.log(matchScoreDevs);
    }
  }, [allSearchDevs, jobId]);

  const getCountry = (code: string) => {
    return countries[code as keyof typeof countries]?.name || "N/A";
  };
  const getCostRate = (developer: any) => {
    if (developer.job_profiles && developer.job_profiles.length > 0) {
      let profileCount = 0;
      let totalRate = 0;
      developer.job_profiles.forEach((profile: { cost_rate: number }) => {
        if (profile.cost_rate && profile.cost_rate > 0) {
          profileCount++;
          totalRate += profile.cost_rate;
        }
      });
      // @ts-ignore
      return Math.round(totalRate / profileCount);
    } else return developer.cost_rate;
  };

  const getLastAppDate = (developer: any) => {
    if (developer.job_profiles && developer.job_profiles.length > 0) return developer.job_profiles[0].created_at;
    else return null;
  };

  const addToSlackList = (id: string) => {
    updateSlackList([...slackList, id]);
  };
  const removeFromSlackList = (id: string) => {
    updateSlackList(slackList.filter((l: string) => l != id));
  };

  const bulkSelect = () => {
    if (bulkSelectCount > matchedDevs.length) setBulkSelectCount(matchedDevs.length);
    let selectedIds = [];
    for (let i = 0; i < bulkSelectCount; i++) {
      const dev = matchedDevs[i];
      if (dev != undefined && dev.id && _.filter(job_profiles, { developer_id: dev.id }).length == 0)
        selectedIds.push(dev.id);
    }
    updateSlackList(selectedIds);
  };

  const sendSlack = async () => {
    if (!process.env.REACT_APP_PORTAL_API_URL) {
      pushMessage({
        type: "danger",
        text: "ERROR: Slack API not defined"
      });
    } else {
      try {
        // const body = new FormData();
        // body.set("jobId", jobId);
        // body.set("devIds", slackList);
        // body.set("message", slackMessage.value);
        // console.log("BODY", body);
        const resp = await fetch(`${process.env.REACT_APP_PORTAL_API_URL}/slack/job-message/send`, {
          method: "POST",
          body: JSON.stringify({
            jobId: jobId,
            devIds: slackList,
            message: slackMessage.value,
            test: process.env.REACT_APP_PORTAL_API_URL?.startsWith("https://portal.g2i.co") ? false : true
          })
        });
        pushMessage({
          type: "success",
          text: "Messaging service called successfully. Reachout labels will update in a moment."
        });
        updateSlackList([]);
      } catch (ex) {
        console.log(ex);
        pushMessage({
          type: "danger",
          text: `ERROR:  Could not send slack message. \n${ex}`
        });
      }
    }
  };

  return (
    <ListContainer>
      {slackList && slackList.length > 0 && (
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 50,
            zIndex: 9999,
            backgroundColor: "lightblue",
            padding: "10px",
            borderRadius: "5px"
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              paddingRight: "4px"
            }}
          >
            {slackList.length}
          </span>{" "}
          developers selected to send job notice in Slack.
          <div>
            <textarea
              rows={8}
              value={slackMessage.value}
              onChange={event => setSlackMessage({ value: event.target.value })}
              //defaultValue={`It looks like you're a match!\n\nYou have the skills associated with the ${jobTitle} role.\n\nPlease apply ASAP at https://portal.g2i.co/jobs#${jobId}`}
              style={{ width: "100%" }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Button type="regular" style={{ marginLeft: "10px" }} onClick={() => sendSlack()}>
              Send Message
            </Button>
            <Button
              type="text"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                updateSlackList([]);
                setSlackMessage({ value: defaultSlackMessage });
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      )}
      <div style={{ display: "flex", gap: "10px" }}>
        <div>{matchedDevs && matchedDevs.length} developers found.</div>
        Select first{" "}
        <input
          type="number"
          value={bulkSelectCount}
          style={{ width: "65px" }}
          min={0}
          max={matchedDevs.length}
          onChange={e => setBulkSelectCount(parseInt(e.target.value))}
        />{" "}
        developers
        <Button type="text" onClick={() => bulkSelect()}>
          [ Apply ]
        </Button>
      </div>

      <div>
        {matchedDevsLoading ? (
          <SuspenseFallback />
        ) : (
          <>
            <div
              style={{ display: "flex", flexWrap: "wrap", alignContent: "center", justifyContent: "center", gap: 20 }}
            >
              {matchedDevs?.slice(0, maxResults).map((d, index) => (
                <SkillsCard
                  key={d.id}
                  tag={(index + 1).toString()}
                  id={d.id}
                  first_name={d.first_name}
                  last_name={d.last_name}
                  country={getCountry(d.country)}
                  rate={getCostRate(d)}
                  lastProfileDate={d.lastAppDate && format(new Date(d.lastAppDate), "MM/dd/yyyy")}
                  skillsMatchedPCT={d.skillsMatch || 0}
                  requiredSkills={requiredSkills}
                  coreSkills={coreSkills}
                  bonusSkills={bonusSkills}
                  developerSkills={d.skills}
                  avatarUrl={getCloudinaryUrl(getAvatarUrl(d))}
                  onSelect={(e: { target: { checked: any } }) =>
                    e.target.checked ? addToSlackList(d.id) : removeFromSlackList(d.id)
                  }
                  selected={slackList.includes(d.id)}
                  reachoutDate={d.job_messages.length > 0 && d.job_messages[0].created_at}
                  reachoutSuccess={d.job_messages.length > 0 && d.job_messages[0].success}
                  reachoutDetails={d.job_messages.length > 0 && d.job_messages[0].metadata}
                />
              ))}
            </div>
            <Button type="regular" onClick={() => setMaxResults(maxResults + 50)}>
              Show 50 More
            </Button>
          </>
        )}
      </div>
    </ListContainer>
  );
};

export default SkillsSearchResults;
