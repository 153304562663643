import { gql } from "@apollo/client";

export const upsertClientsMutation = gql`
  mutation upsertClients($display_name: String!, $email: String!, $username: String = null, $company_id: uuid!) {
    insert_users_one(
      object: {
        display_name: $display_name
        email: $email
        username: $username
        role: "client"
        company_user: {
          data: { company_id: $company_id }
          on_conflict: { constraint: company_users_pkey, update_columns: company_id }
        }
      }
      on_conflict: { constraint: users_email_key, update_columns: [email, display_name, username] }
    ) {
      id
      display_name
      email
      username
      company_user {
        company {
          id
          name
        }
      }
    }
  }
`;
