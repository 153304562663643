import { styled } from "../util/styled";

export const SidenavGroup = styled.div`
  margin-top: ${({ theme }) => theme.space}px;
  width: 100%;

  ${({ title, theme }) =>
    title
      ? `
  ::before {
    content: attr(title);
    display: grid;
    align-items: center;
    grid-template-columns: 32px auto;
    padding: ${theme.space / 2}px;
    font-size: .8rem;
    color: #fff7;
    text-transform: uppercase;
    font-weight: 700;
    white-space: pre;
  }`
      : ""}
`;
