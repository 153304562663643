import React, { FC, ReactNode, HTMLAttributes } from "react";

import { SingleContainer, Content, Header, SplitContainer, ActionsContainer } from "./Card.styled";

type BaseCardProps = {
  /** Removes padding on content */
  condensed?: boolean;
  fullWidth?: boolean;
} & HTMLAttributes<HTMLDivElement>;

type BaseCardPropsWithoutColumns = {
  title?: ReactNode;
  columns?: never;
  children: ReactNode;
  action?: ReactNode;
} & BaseCardProps;

type CardPropsWithoutColumnsAndWithActions = {
  action: ReactNode;
  actionPosition?: "start" | "end";
} & BaseCardPropsWithoutColumns &
  BaseCardProps;

type CardPropsWithoutColumnsAndWithoutActions = {
  action?: never;
  actionPosition?: never;
} & BaseCardPropsWithoutColumns &
  BaseCardProps;

type CardColumn = {
  title?: ReactNode;
  content: ReactNode;
};

type CardPropsWithColumns = {
  columns: CardColumn[];
  /** Widths of columns: "1fr 1fr" or similar */
  columnWidths?: string;
  title?: never;
  children?: never;
} & BaseCardProps;

export type CardProps =
  | CardPropsWithColumns
  | CardPropsWithoutColumnsAndWithActions
  | CardPropsWithoutColumnsAndWithoutActions;

export const Card: FC<CardProps> = ({ condensed, fullWidth, ...props }) => {
  if (!props.columns) {
    // We're not a split card with columns
    const { title, children, action, actionPosition, ...singleProps } = props;
    return (
      <SingleContainer {...singleProps} fullWidth={Boolean(fullWidth)}>
        {title && (
          <Header actionPosition={actionPosition} hasAction={Boolean(action)}>
            {actionPosition === "start" && <ActionsContainer>{action}</ActionsContainer>}
            {title}
            {actionPosition !== "start" && <ActionsContainer>{action}</ActionsContainer>}
          </Header>
        )}
        <Content condensed={Boolean(condensed)}>{children}</Content>
      </SingleContainer>
    );
  } else {
    const { columns, columnWidths } = props;
    return (
      <SplitContainer
        {...props}
        style={{ gridTemplateColumns: columnWidths }}
        fullWidth={Boolean(fullWidth)}
        columnCount={columns.length}
      >
        {columns.map((c, i) => (
          <Header key={`card-header-${i}`} hasAction={false /*for now */}>
            {c.title}
          </Header>
        ))}
        {columns.map((c, i) => (
          <Content key={`card-content-${i}`} condensed={Boolean(condensed)}>
            {c.content}
          </Content>
        ))}
      </SplitContainer>
    );
  }
};
