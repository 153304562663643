import { styled } from "../util/styled";
import { keyframes } from "@emotion/core";

const spin = keyframes`
from {
    transform: rotate(0deg)
}

to {
    transform: rotate(360deg)
}
`;

export const Spinner = styled.div<{ size?: number }>(({ size = 24 }) => ({
  width: size,
  height: size,
  borderRadius: "50%",
  background: "conic-gradient(transparent, currentColor)",
  alignSelf: "center",
  animation: `${spin} .6s infinite linear`
}));
