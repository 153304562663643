// /* eslint-disable no-loop-func */
import fetch from "isomorphic-fetch";
import React, { ReactElement } from "react";
import * as Sentry from "@sentry/browser";
import { LoadingLogoWithTitle } from "./LoadingLogoWithTitle";
import { RetryLink } from "@apollo/client/link/retry";
import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactNode } from "react";
import { setContext } from "@apollo/client/link/context";
import { authorizationParams } from "../constants";

const JWTExpiredMessage = "JWTExpired";

export function ApolloProviderWithClient({ children }: { children: ReactNode }): ReactElement {
  const { getAccessTokenSilently } = useAuth0();

  const client = React.useMemo<ApolloClient<NormalizedCacheObject> | null>(() => {
    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => !!error
      }
    });

    if (typeof process === "undefined") return null;

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently({ authorizationParams });

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ""
        }
      };
    });

    const errorLink = onError(({ graphQLErrors, operation, forward, networkError }) => {
      if (graphQLErrors?.length) {
        graphQLErrors.map(async ({ message, locations, path }) => {
          if (message.includes(JWTExpiredMessage)) {
            const token = await getAccessTokenSilently({ authorizationParams });
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`
              }
            });

            if (operation.operationName === "mutation") {
              console.log("Retrying mutation:", operation);
            }

            return forward(operation);
          } else {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            return Sentry.captureException({ message, locations, path });
          }
        });
      }

      if (networkError) {
        console.log("[Network error]: ", networkError);
        if (process.env.NODE_ENV === "production") {
          Sentry.captureException(networkError);
        }
      }
    });

    const batchHTTPLink = new BatchHttpLink({
      uri: process.env.REACT_APP_API_URL,
      fetch,
      batchInterval: 10,
      batchMax: 5
    });

    return new ApolloClient({
      link: from([authLink, errorLink, retryLink, batchHTTPLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "network-only"
        }
      }
    });
  }, [getAccessTokenSilently]);

  if (!client) return <LoadingLogoWithTitle title="Loading..." isLoading />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
