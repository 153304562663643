import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FC, ReactElement } from "react";

interface AuthenticationGuardProps {
  component: ReactElement;
}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = ({ component }) => {
  const Component = withAuthenticationRequired(() => component);
  return <Component />;
};
