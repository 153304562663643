import { styled } from "../util/styled";

const cardHeaderHeight = 44;
const lineHeight = 27;

export const SingleContainer = styled("div", {
  shouldForwardProp: p => !["action", "actionPosition", "fullWidth", "columnWidths"].includes(p)
})<{
  fullWidth: boolean;
}>(({ fullWidth, theme }) => ({
  display: "grid",
  gridTemplateRows: "min-content auto",
  height: "100%",
  border: `1px solid ${theme.colors.lightBorder}`,
  borderRadius: 2,
  background: theme.colors.white,
  overflow: "auto",
  ...(fullWidth && { width: "100%" })
}));

export const Content = styled("div", {
  shouldForwardProp: prop => prop !== "condensed"
})<{ condensed: boolean }>`
  padding: ${({ condensed, theme }) => (condensed ? 0 : theme.space)}px;
  overflow: auto;

  > div,
  > p,
  > span {
    margin: 0;
    line-height: ${lineHeight}px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;

  > *:not(:first-of-type) {
    margin-left: ${({ theme }) => theme.space / 2}px;
  }
`;

export const SplitContainer = styled(SingleContainer, { shouldForwardProp: p => p !== "columnCount" })<{
  columnCount: number;
}>`
  grid-template-columns: repeat(${({ columnCount }) => columnCount}, 1fr);

  > div:nth-of-type(even) {
    border-left: 1px solid ${({ theme }) => theme.colors.lightBorder};
  }
`;

export const Header = styled.div<{
  hasAction: boolean;
  actionPosition?: "start" | "end";
}>(({ theme, hasAction, actionPosition }) => ({
  display: "flex",
  alignItems: "center",
  padding: `${theme.space / 2}px ${theme.space}px`,
  borderBottom: `1px solid ${theme.colors.lightBorder}`,
  color: theme.colors.textH2,
  fontWeight: 500,
  backgroundColor: theme.colors.cardHeader,
  minHeight: cardHeaderHeight,
  lineHeight: `${lineHeight}px`,

  ...(hasAction && {
    display: "grid",
    gridTemplateColumns: actionPosition === "start" ? "max-content auto" : "auto max-content",
    gap: theme.space
  })
}));
