import React from "react";

import { Modal } from "./Modal";
import { Content } from "./ModalSuspenseFallback.styled";
import { LogoPulseLoader } from "./LogoPulseLoader";

export const ModalSuspenseFallback = () => (
  <Modal>
    <Content>
      <LogoPulseLoader />
    </Content>
  </Modal>
);
