import React, { useState } from "react";
import { Button } from "./Button";
import { Input } from "./Input";
import ResumeSearchHints from "./ResumeSearchHints";
import { DeveloperType } from "./ResumeSearchContainer";

export function ResumeSearchInput({
  setSearchData
}: {
  setSearchData: React.Dispatch<
    React.SetStateAction<{
      result: DeveloperType[];
      error?: "" | undefined;
    }>
  >;
}) {
  const [searchValue, setSearchValue] = useState("");
  const [displayHint, setDisplayHint] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const performSearch = async (value: string) => {
    try {
      const apiUrl = process.env.REACT_APP_PORTAL_API_URL;
      if (!apiUrl) throw new Error("API URL is not defined");
      const results = await fetch(`${apiUrl}/developer/search-resume?q=${value}`).then(r => r.json());
      setSearchData(results);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Input
        onChange={e => {
          setSearchValue(e.target.value);
        }}
        typeOfInput="regular"
        name="search"
        type="text"
        placeholder="Search resumes..."
        value={searchValue}
        onKeyDown={async e => {
          if (e.key === "Enter") {
            setIsLoading(true);
            await performSearch(searchValue);
            setIsLoading(false);
          }
        }}
      />
      <div
        style={{
          display: "flex",
          gap: "1rem"
        }}
      >
        <Button
          onClick={async () => {
            setIsLoading(true);
            await performSearch(searchValue);
            setIsLoading(false);
          }}
          style={{ maxWidth: "fit-content" }}
          type="regular"
          color="primary"
          loading={isLoading}
          disabled={!Boolean(searchValue)}
        >
          Search
        </Button>
        <Button
          type="regular"
          style={{
            maxWidth: "fit-content"
          }}
          onClick={() => {
            setDisplayHint(!displayHint);
          }}
        >
          Display Hint
        </Button>
      </div>
      <ResumeSearchHints displayHint={displayHint} />
    </>
  );
}
