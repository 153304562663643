import { styled } from "../util/styled";
import { inputStyles } from "./Input.styled";

export const Container = styled.select<{ condensed?: boolean }>(({ theme, condensed }) => {
  const styles = inputStyles({ theme });

  return {
    ...styles,
    cursor: "pointer",
    padding: condensed ? theme.space / 2 : styles.padding,
    height: condensed ? "auto" : styles.height
  };
});

export const SelectWrapper = styled("div")<{ hasText?: boolean }>(({ hasText, theme }) => ({
  display: "grid",
  width: "100%",
  gridTemplateRows: hasText ? "min-content auto" : "min-content",
  gap: theme.space / 2,
  position: "relative"
}));

export const MultiSelectionList = styled.div(() => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  height: "100%",
  width: "100%",
  padding: "4px 8px",
  pointerEvents: "none"
}));

export const MultiSelectOption = styled.span(() => ({
  background: "#e9eff7",
  borderRadius: 3,
  padding: 5,
  pointerEvents: "auto",
  marginRight: 8
}));
