import { styled } from "../util/styled";
import { Logo } from "./Logo";

export const LogoPulseLoader = styled(Logo)`
  animation: 2s logoPulse infinite;
  display: block;
  border-radius: 50%;

  @keyframes logoPulse {
    0% {
      transform: scale(1);
    }
    15% {
      box-shadow: 0 0 0 0px rgba(255, 43, 42, 0.4);
    }
    50% {
      transform: scale(1.3);
    }
    60% {
      box-shadow: 0 0 0 40px rgba(255, 43, 42, 0);
    }
    100% {
      transform: scale(1);
    }
  }
`;
