import React from "react";
import { Card } from "../components/Card";
import { ListPageContent } from "../components/ListPageContent";
import { styled } from "../util/styled";
import { ResumeSearchContainer } from "../components/ResumeSearchContainer";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const InnerContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ResumeSearch = () => {
  return (
    <Container>
      <ListPageContent hasBreakdown={false}>
        <Card condensed fullWidth title="Resume Search">
          <InnerContainer>
            <ResumeSearchContainer />
          </InnerContainer>
        </Card>
      </ListPageContent>
    </Container>
  );
};

export default ResumeSearch;
