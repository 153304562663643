import React, { useEffect, useState } from "react";
import ErrorToast from "./ErrorToast";
import { Link, useParams, useHistory } from "react-router-dom";
import { getAllActiveJobs, getAllActiveJobs_jobs, getAllActiveJobs_jobs_job_skills } from "../queries";
import { useQuery, gql } from "@apollo/client";
import { Select } from "./Select";
import { Button } from "./Button";
import { Modal, ModalHeader, ModalContent } from "./Modal";

import _ from "lodash";
import { formatDistance } from "date-fns";
import { Card } from "./Card";
import styled from "@emotion/styled";
import SkillsSearchResults from "./SkillsSearch/SkillsSearchResults";
import { Chip } from "./Chip";
import { theme } from "../util/theme";

const InnerContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SkillsCard = styled.div`
  flex: 1;
  background-color: white;
  font-size: 16px;
  font-weight: 600;
  margin: 5px;
  padding: 5px;
  box-shadow: 0 2px 6px #0000002b;
`;
export function SkillsSearchContainer() {
  const { jobId }: { jobId: string } = useParams();
  const [localError, setLocalError] = useState<string>("");
  const [selectedJob, setSelectedJob] = useState<getAllActiveJobs_jobs>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [searchLoading, setSearchLoading] = useState<boolean>();
  const [jobSelectModalOpen, setJobSelectModalOpen] = useState<boolean>();
  const history = useHistory();
  const { data: activeJobs, error: getJobsError, loading: isQueryLoading } = useQuery<getAllActiveJobs>(
    gql`
      query getAllActiveJobs {
        jobs(
          where: {
            status: { _in: [NEEDS_PROFILES, LEAD, PROFILES_SENT, WAITING_ON_CLIENT, ACTIVE, FOLLOW_UP] }
            deleted_at: { _is_null: true }
          }
          order_by: { created_at: desc }
        ) {
          company {
            id
            name
          }
          id
          title
          type
          created_at
          location
          budget_hourly_min
          budget_hourly_max
          budget_monthly
          yearly_budget_min
          yearly_budget_max
          job_profiles {
            developer_id
            created_at
            status
          }
          job_skills {
            job_skill_type
            skill {
              id
              name
              skill_type
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (jobId && jobId.length > 1) {
      const job = _.find(activeJobs?.jobs, { id: jobId });
      if (job) {
        selectCompany(job?.company.id);
        selectJob(job.id);
      }
    }
  }, [activeJobs]);

  const selectCompany = (companyId: string) => {
    activeJobs && setSelectedCompanyId(_.find(activeJobs.jobs, { company: { id: companyId } })?.company.id);
    setSelectedJob(undefined);
  };
  const selectJob = (jobId: string) => {
    setJobSelectModalOpen(false);
    setSelectedJob(_.find(activeJobs?.jobs, { id: jobId }));
  };

  return (
    <>
      <Card condensed fullWidth>
        <InnerContainer>
          <div>
            <ErrorToast error={getJobsError} />
            {localError && localError.length > 1 && <div style={{ color: "red" }}>{localError}</div>}
            <Modal aria-label="Select Company" isOpen={jobSelectModalOpen}>
              <ModalHeader close={() => setJobSelectModalOpen(false)}>
                Skills Search - Select Company and job.
              </ModalHeader>
              <ModalContent>
                <Select
                  label="Company"
                  value={selectedCompanyId || ""}
                  style={{ gridColumn: "1 / span 3", marginRight: "20px" }}
                  onChange={e => selectCompany(e.target.value)}
                >
                  <option value="">{!activeJobs ? "Loading..." : "Select Company"}</option>
                  {_.uniqBy(activeJobs?.jobs, "company.id").map((job: getAllActiveJobs_jobs) => (
                    <option value={job.company.id} key={job.company.id}>
                      {job.company.name} - job posted {formatDistance(new Date(job.created_at), Date.now()) + " ago"}
                    </option>
                  ))}
                </Select>
                <Select
                  label="Job"
                  value={selectedJob?.id || ""}
                  style={{ marginRight: "20px", gridColumn: "1 / span 3" }}
                  onChange={e => selectJob(e.target.value)}
                >
                  <option value="">{!selectedCompanyId ? "No Company Selected" : "Select Job"}</option>
                  {activeJobs?.jobs.map(
                    (job: getAllActiveJobs_jobs) =>
                      job.company.id === selectedCompanyId && (
                        <option value={job.id} key={job.id}>
                          {job.title} - {job.company.name} -{" "}
                          {formatDistance(new Date(job.created_at), Date.now()) + " ago"}
                        </option>
                      )
                  )}
                </Select>
              </ModalContent>
            </Modal>
            {selectedJob && (
              <div>
                <h3 style={{ lineHeight: "1" }}>{`${selectedJob.company.name} - ${selectedJob.title}`}</h3>
                <div>
                  Applications since posted{" "}
                  {activeJobs?.jobs.map(
                    (job: getAllActiveJobs_jobs) =>
                      job.id === selectedJob.id && (
                        <span key="length_open"> {formatDistance(new Date(job.created_at), Date.now())} ago</span>
                      )
                  )}
                </div>
                <div>
                  <Chip
                    key="total_chip"
                    label={`Total: ${selectedJob?.job_profiles.length}`}
                    style={{
                      margin: "2px",
                      backgroundColor: "rgba(169, 215, 255, 0.0)",
                      fontWeight: "inherit",
                      color: "rgba(0, 0, 0, .75)"
                    }}
                  />
                  {Object.entries(_.countBy(selectedJob?.job_profiles, "status")).map(([k, v]) => (
                    <Chip
                      key={k}
                      label={`${k.replaceAll("_", " ").toLocaleLowerCase()}: ${v}`}
                      style={{
                        margin: "2px",
                        backgroundColor: "rgba(169, 215, 255, 0)",
                        fontWeight: "inherit",
                        color: "rgba(0, 0, 0, .75)",
                        textTransform: "capitalize"
                      }}
                    />
                  ))}
                </div>
                <div style={{ display: "flex", gap: 20 }}>
                  <div style={{ flex: 1 }}>
                    <div>Budget</div>
                    <div style={{ display: "flex", gap: 10 }}>
                      <div style={{ color: "rgba(0, 0, 0, 0.75)", fontSize: 14 }}>
                        Hourly:{" "}
                        <b>
                          ${selectedJob.budget_hourly_min} - ${selectedJob.budget_hourly_max}
                        </b>
                      </div>
                      <div style={{ color: "rgba(0, 0, 0, 0.75)", fontSize: 14 }}>
                        Monthly: <b>${selectedJob?.budget_monthly}</b>
                      </div>
                      <div style={{ color: "rgba(0, 0, 0, 0.75)", fontSize: 14 }}>
                        Salary:{" "}
                        <b>
                          ${selectedJob?.yearly_budget_min} - ${selectedJob?.yearly_budget_max}
                        </b>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 2, maxWidth: "70%", overflow: "clip" }}>
                    <div>Accepted Locations ({selectedJob?.location?.length})</div>
                    <div style={{ color: "rgba(0, 0, 0, 0.75)", fontSize: 14 }}>
                      {selectedJob?.location?.length > 0
                        ? selectedJob?.location.toString()
                        : `no location defined. showing candidates from US, CA, and BR`}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button type="text" onClick={() => history.push("/skills-search")}>
                    [ Select Another Job ]
                  </Button>
                  <Link to={() => `/jobs/${selectedJob?.id}/overview`}>[ View Job Details ]</Link>
                </div>
              </div>
            )}
          </div>
        </InnerContainer>
      </Card>
      {selectedJob && (
        <>
          <div>
            <div style={{ display: "flex", marginTop: "12px", marginBottom: "12px" }}>
              <SkillsCard>
                <div style={{ marginRight: "4px" }}>Required</div>
                <div>
                  {selectedJob &&
                    selectedJob?.job_skills.map((skill: getAllActiveJobs_jobs_job_skills) => {
                      if (skill.job_skill_type === "REQUIRED")
                        return (
                          <Chip
                            key={"REQ" + skill.skill.id}
                            condensed
                            label={skill.skill.name}
                            style={{ marginRight: "4px", backgroundColor: theme.backgrounds.greenTrans }}
                          />
                        );
                    })}
                </div>
              </SkillsCard>
              <SkillsCard>
                <div style={{ marginRight: "4px" }}>Core </div>
                {selectedJob &&
                  selectedJob?.job_skills.map((skill: getAllActiveJobs_jobs_job_skills) => {
                    if (skill.job_skill_type === "CORE")
                      return (
                        <Chip
                          key={"REQ" + skill.skill.id}
                          condensed
                          label={skill.skill.name}
                          style={{ marginRight: "4px", backgroundColor: theme.backgrounds.blueTrans }}
                        />
                      );
                  })}
              </SkillsCard>
              <SkillsCard>
                <div style={{ marginRight: "4px" }}>Bonus</div>
                {selectedJob &&
                  selectedJob?.job_skills.map((skill: getAllActiveJobs_jobs_job_skills) => {
                    if (skill.job_skill_type === "BONUS")
                      return (
                        <Chip
                          key={"REQ" + skill.skill.id}
                          condensed
                          label={skill.skill.name}
                          style={{ marginRight: "4px", backgroundColor: theme.backgrounds.yellowTrans }}
                        />
                      );
                  })}
              </SkillsCard>
            </div>
          </div>
        </>
      )}
      <Card condensed fullWidth style={{ backgroundColor: "#f6f9fc" }}>
        <div style={{ marginLeft: "6px", marginRight: "6px", backgroundColor: "#f6f9fc" }}>
          {selectedJob?.id ? (
            <SkillsSearchResults
              jobId={selectedJob?.id}
              jobTitle={`${selectedJob?.title} for ${selectedJob?.company?.name}`}
              job_skills={selectedJob?.job_skills}
              job_profiles={selectedJob?.job_profiles}
              location={selectedJob?.location}
            />
          ) : (
            <div>Select a Job</div>
          )}
        </div>
      </Card>
    </>
  );
}
