import React from "react";
import { render } from "react-dom";
import { Global, ThemeContext } from "@emotion/core";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";

import App from "./components/App";
import { globalStyles } from "./util/globalStyles";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./util/theme";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { useAuth0 } from "@auth0/auth0-react";
import Auth0ProviderWithHistory from "./components/Auth0ProviderWithHistory";

const displayErrorDescription = (error: Error) => {
  if ("error_description" in error) {
    const { error_description } = error as any;
    return error_description;
  }
  return error.message;
};

const ErrorWrapper = () => {
  const { error, logout } = useAuth0();

  return error ? (
    <div>
      <p>Not Authorized: {displayErrorDescription(error)}</p>
      <button onClick={() => logout()}>Logout</button>
    </div>
  ) : (
    <App />
  );
};

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    environment: process.env.NODE_ENV,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    beforeSend(event) {
      if ("Cypress" in window) {
        document.write(JSON.stringify(event, null, 2));
      }
      return event;
    }
  });
}

render(
  <ThemeContext.Provider value={theme}>
    <Global styles={globalStyles} />
    <Router>
      <>
        <ErrorBoundary>
          <Auth0ProviderWithHistory>
            <ErrorWrapper />
          </Auth0ProviderWithHistory>
        </ErrorBoundary>
      </>
    </Router>
  </ThemeContext.Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
