import React, { FC, SelectHTMLAttributes } from "react";

import { Container, SelectWrapper, MultiSelectionList, MultiSelectOption } from "./Select.styled";
import { Label } from "./Label";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  condensed?: boolean;
  multi?: boolean;
  deselectVal?: (val: string) => void;
  value?: any;
}

export const Select: FC<SelectProps> = ({ children, label, style, multi, deselectVal, ...props }) => {
  return (
    <Label hasText={Boolean(label)} style={style}>
      {label}
      <SelectWrapper hasText={Boolean(label)}>
        {multi && Array.isArray(props.value) && deselectVal && (
          <MultiSelectionList>
            {props.value.map(eachVal => {
              const isObject = typeof eachVal === "object";
              const value = isObject ? eachVal.id : eachVal;
              const name = isObject ? eachVal.name : eachVal;

              return <MultiSelectOption onClick={e => deselectVal(value)}>{name} &#10060;</MultiSelectOption>;
            })}
          </MultiSelectionList>
        )}
        <Container {...props}>{children}</Container>
      </SelectWrapper>
    </Label>
  );
};
