import React, { ReactNode } from "react";
import "@reach/dialog/styles.css";
import Dialog from "@reach/dialog";

import { styled } from "../util/styled";

export const Modal = styled(Dialog)`
  &[data-reach-dialog-content] {
    padding: 0;
    border-radius: 4px;
    box-shadow: 0 8px 16px #0001;
    overflow: auto;
    max-width: 90vw;
    width: fit-content;
  }
`;

export default Modal;

export const ModalContent = styled.div`
  padding: ${({ theme }) => theme.space}px;
  p:first-of-type {
    margin-top: 0;
  }
`;

export const ModalHeaderContainer = styled.div`
  height: 50px;
  padding: ${({ theme }) => theme.space}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightBorder};
`;

export const ModalActionsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content max-content;
  gap: ${({ theme }) => theme.space / 2}px;
`;

type ModalHeaderProps = {
  children: string | ReactNode;
  close: () => void;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, close }) => (
  <ModalHeaderContainer>
    <div style={{ width: "100%" }}>{children}</div>
    <button type="button" style={{ all: "unset", cursor: "pointer" }} onClick={close}>
      <span role="img" aria-label="close modal image" style={{ pointerEvents: "none" }}>
        ✖️
      </span>
    </button>
  </ModalHeaderContainer>
);
