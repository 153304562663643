import { MenuButton as ReachMenuButton } from "@reach/menu-button";
import { contrast } from "chroma-js";

import { BaseButtonProps } from "./Button";
import { Theme } from "../util/theme";
import { styled } from "../util/styled";

type StyledButtonProps = {
  type?: "button" | "submit" | "reset";
  color?: keyof Theme["colors"];
  outline: boolean;
  fullWidth?: boolean;
};

const propsToFilterOut = ["color", "outline", "fullWidth"];

const getTextColor = (isButtonOutline: boolean, theme: Theme, color: keyof Theme["colors"] | undefined) => {
  if (!color) {
    return theme.colors.text;
  }
  if (isButtonOutline) {
    return theme.colors[color];
  }
  // 4.15 is an ideal contrast ratio for accessibility.
  if (contrast(theme.colors.white, theme.colors[color]) < 4.15) {
    return theme.colors.text;
  }
  return theme.colors.white;
};

const createButtonStyles = (type: BaseButtonProps["type"]) => ({
  theme,
  color,
  outline,
  fullWidth
}: {
  theme: Theme;
} & StyledButtonProps) =>
  ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.colors[color || "shadowBlue"]}`,
    borderRadius: "2px",
    fontSize: "0.8rem",
    minWidth: 140,
    margin: 0,
    fontWeight: 500,
    justifyContent: "center",
    padding: `${theme.space / 2}px ${theme.space}px;`,
    background: color && !outline ? theme.colors[color] : "transparent",
    cursor: "pointer",
    color: getTextColor(outline, theme, color),
    width: fullWidth ? "100%" : undefined,

    "&[disabled]": {
      opacity: 0.5
    } as const,

    ' [role="img"]': {
      lineHeight: 1
    } as const,

    ...(type === "menu" &&
      ({
        justifyContent: "flex-start",
        padding: `${theme.space / 2}px ${theme.space * 4}px ${theme.space / 2}px
      ${theme.space}px`,
        "&::after": {
          content: `""`,
          position: "absolute",
          top: "50%",
          transform: "translateY(calc(4px - 50%))",
          right: `${theme.space}px`,
          display: "block",
          width: "0",
          height: "0",
          border: "6px solid transparent",
          borderTopColor: "currentColor",
          justifySelf: "flex-end"
        }
      } as const))
  } as const);

export const Container = styled("button", { shouldForwardProp: p => !propsToFilterOut.includes(p) })<StyledButtonProps>(
  createButtonStyles("regular")
);
export const MenuButton = styled(ReachMenuButton, {
  shouldForwardProp: p => !propsToFilterOut.includes(p)
})<StyledButtonProps>(createButtonStyles("menu"));

export const TextContainer = styled.span(({ theme }) => ({
  color: theme.colors.primary,
  cursor: "pointer",
  ":hover": { textDecoration: "underline" }
}));

Container.defaultProps = {
  role: "button",
  type: "button"
};
