import { styled } from "../util/styled";

export const Container = styled.nav`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.shadowBlue};
  grid-area: sidenav;
  color: ${({ theme }) => theme.colors.white};
  overflow: auto;
`;
