import { styled } from "../util/styled";
import { PageContent } from "./PageContent";

export const ListPageContent = styled(PageContent, { shouldForwardProp: p => p !== "hasBreakdown" })<{
  hasBreakdown?: boolean;
}>`
  display: grid;
  grid-template-rows: ${({ hasBreakdown }) => (hasBreakdown ? "80px auto 1fr" : "auto 1fr")};
  justify-items: flex-start;
  gap: ${({ theme }) => theme.space}px;
  width: 100%;
  height: 100%;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

ListPageContent.defaultProps = { hasBreakdown: true };
