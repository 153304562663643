import React, { ReactNode, MouseEvent } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { Container, Tbody, Td, Th, Thead, Tr } from "./Table.styled";
import { Column } from "../hooks/useColumnSort";
import { theme } from "../util/theme";

type TableProps<DataObject> = {
  data: DataObject[];
  condensed?: boolean;
  columns: {
    heading: (datum: DataObject) => ReactNode;
    cell: (datum: DataObject, index: number) => ReactNode;
    columnToSortBy?: keyof DataObject;
    hidden?: boolean;
  }[];
  sort?: (columnToSortBy: keyof DataObject) => void;
  sortedColumn?: Column<DataObject>;
  onRowClick?: (row: DataObject, event: MouseEvent<HTMLTableRowElement>) => void;
  /* A tuple of size values (300px, 20%, etc.) representing the alignments of each column */
  columnWidths?: string[];
  /* A tuple representing the alignments of each column */
  columnAlignments?: Array<"left" | "center" | "right">;
  highlightedRowIndex?: number;
  topAlign?: boolean;
};

// No arrow because this file is JSX and this component is <Generic>
export function Table<T extends object>({
  onRowClick,
  condensed,
  data,
  columns,
  sort,
  sortedColumn,
  highlightedRowIndex,
  columnWidths = [],
  columnAlignments = [],
  topAlign = false
}: TableProps<T>) {
  return (
    <Container condensed={Boolean(condensed)}>
      <Thead>
        <Tr emptyRow={false}>
          {columns
            .filter(c => c.hidden !== true)
            .map(({ heading, columnToSortBy }, i) => {
              let sortIcon: React.ReactNode = null;

              if (columnToSortBy) {
                if (sortedColumn && sortedColumn[columnToSortBy]) {
                  sortIcon = sortedColumn[columnToSortBy] === "asc_nulls_last" ? <FiChevronUp /> : <FiChevronDown />;
                }
              }

              return (
                <Th
                  onClick={() => {
                    sort && columnToSortBy && sort(columnToSortBy);
                  }}
                  tabIndex={columnToSortBy && 0}
                  onKeyUp={({ key }) => {
                    if (["Enter", "Space", " "].includes(key)) {
                      sort && columnToSortBy && sort(columnToSortBy);
                    }
                  }}
                  hasSort={Boolean(columnToSortBy)}
                  style={{
                    width: columnWidths[i],
                    textAlign: columnAlignments[i],
                    ...(columnToSortBy && { cursor: "pointer" })
                  }}
                  key={`table-header-cell-${i}`}
                >
                  {heading(data[i])} {sortIcon}
                </Th>
              );
            })}
        </Tr>
      </Thead>
      <Tbody>
        {data.length ? (
          data.map((row, rowIndex) => (
            <Tr
              emptyRow={false}
              key={`table-row-${rowIndex}`}
              style={{ background: rowIndex === highlightedRowIndex ? theme.colors.lightBorder : "none" }}
              onClick={onRowClick ? event => onRowClick(row, event) : undefined}
            >
              {columns
                .filter(c => c.hidden !== true)
                .map(({ cell }, i) => (
                  <Td
                    key={`table-cell-${rowIndex}-${i}`}
                    style={{
                      width: columnWidths[i],
                      textAlign: columnAlignments[i],
                      verticalAlign: topAlign ? "top" : "center"
                    }}
                  >
                    {cell(row, rowIndex)}
                  </Td>
                ))}
            </Tr>
          ))
        ) : (
          <Tr emptyRow>
            <Td colSpan={columns.length}>This table has no data yet.</Td>
          </Tr>
        )}
      </Tbody>
    </Container>
  );
}
