import chroma from "chroma-js";
import { HTMLAttributes } from "react";
import { keyframes } from "@emotion/core";

import { Theme } from "../util/theme";
import { styled } from "../util/styled";

const appear = keyframes`from {
    opacity: 0;
    transform: translateY(8px);
}

to {
    opacity: 1;
    transform: none;
}`;

export const Container = styled.div<{ color?: keyof Theme["colors"] } & HTMLAttributes<HTMLDivElement>>(
  ({ theme, color, onClick }) => ({
    animation: `${appear} .6s cubic-bezier(.8, 0, 0, .6) forwards`,
    minHeight: 48,
    maxWidth: "30vw",
    lineHeight: "24px",
    position: "fixed",
    bottom: theme.space,
    right: theme.space,
    color: theme.colors.white,
    fontSize: "0.9rem",
    backgroundColor: chroma(theme.colors[color ? color : "primary"])
      .alpha(0.95)
      .hex(),
    borderRadius: 4,
    cursor: Boolean(onClick) ? "pointer" : "initial",
    boxShadow: "0 2px 4px #0003",
    padding: theme.space,
    paddingRight: `calc(${theme.space}px + 40px)`,
    backdropFilter: "blur(10px)",
    zIndex: 10e8
  })
);

export const DismissButton = styled.div(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  right: 0,
  width: 40,
  height: "100%",
  padding: theme.space / 2,
  background: "#0001",

  ":hover": {
    background: "#0003"
  },

  "::before, ::after": {
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    margin: "0 auto",
    content: '""',
    width: "50%",
    height: 2,
    background: "white"
  },

  "::before": {
    transform: "translateY(-50%) rotate(45deg)"
  },

  "::after": {
    transform: "translateY(-50%) rotate(-45deg)"
  }
}));
