import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Table } from "../components/Table";
import { Card } from "../components/Card";
import { ListPageContent, ListContainer } from "../components/ListPageContent";
import { Avatar } from "../components/Avatar";
import { SuspenseFallback } from "../components/SuspenseFallback";
import { Toast } from "../components/Toast";
import { Button } from "../components/Button";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { getClientUsers, getClientUsers_users } from "../queries";
import { useSuspenseLoading } from "../hooks/useSuspenseLoading";
import { useColumnSort } from "../hooks/useColumnSort";
import { Paginator } from "../components/Paginator";
import { useURLState } from "../hooks/useURLState";
import { ModalsContext } from "../contexts/ModalsContext";
import AddClientModal from "../components/AddClientModal";
import EditClientModal from "../components/EditClientModal";

const resultsPerPage = 25;

const ClientUsers: FC = () => {
  const { push } = useHistory();
  const { sortedColumn, handleSort } = useColumnSort<any>({ display_name: "asc_nulls_last" });
  const [editModalData, setEditModalData] = useState<getClientUsers_users | null>(null);
  const [offset, setOffset] = useURLState("offset", 0);
  const { openModal } = useContext(ModalsContext);

  const { data, error, loading, refetch } = useQuery<getClientUsers>(
    gql`
      query getClientUsers($limit: Int, $offset: Int, $sort: [users_order_by!]) {
        users_aggregate(where: { role: { _eq: "client" } }) {
          aggregate {
            count
          }
        }
        users(limit: $limit, offset: $offset, order_by: $sort, where: { role: { _eq: "client" } }) {
          id
          display_name
          email
          role
          username
          company_user {
            company {
              id
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        sort: sortedColumn,
        limit: resultsPerPage,
        offset
      }
    }
  );

  useEffect(() => {
    if (!editModalData) return;
    openModal("Edit Client");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModalData]);

  const shouldShowLoading = useSuspenseLoading(loading);
  const errorToDisplay = error;

  const paginate = useCallback(
    data => {
      const selected = data.selected;
      const offset = Math.ceil(selected * resultsPerPage);
      setOffset(offset);
    },
    [setOffset]
  );

  return (
    <ListPageContent hasBreakdown={false}>
      <AddClientModal refetch={refetch} />
      <EditClientModal refetch={refetch} editModalData={editModalData} />
      {errorToDisplay && <Toast type="danger">{errorToDisplay.message}</Toast>}
      <div style={{ margin: "0 auto" }}>
        <Paginator
          onPage={paginate}
          initialPage={offset / resultsPerPage}
          pageCount={(data?.users_aggregate.aggregate!?.count ?? resultsPerPage) / resultsPerPage}
        />
      </div>
      <ListContainer>
        <Card
          fullWidth
          condensed
          title={`Clients (${data?.users.length ?? 0} of ${data?.users_aggregate.aggregate!?.count ?? "-"} Result${
            data?.users.length && data?.users.length === 1 ? "" : "s"
          })`}
          actionPosition="end"
          action={
            <Button
              type="regular"
              onClick={() => {
                openModal("Add Client");
              }}
            >
              Add New Client
            </Button>
          }
        >
          {shouldShowLoading ? (
            <SuspenseFallback />
          ) : (
            <Table
              data={data?.users ?? []}
              sort={handleSort}
              sortedColumn={sortedColumn}
              onRowClick={client => {
                push(`/clients/${client.id}`);
                setEditModalData(client);
              }}
              columns={[
                {
                  heading: () => "Name",
                  cell: a => <Avatar name={`${a.display_name}`} showName />,
                  columnToSortBy: "display_name"
                },
                {
                  heading: () => "Email",
                  cell: a => a.email,
                  columnToSortBy: "email"
                },
                {
                  heading: () => "Username",
                  cell: a => a.username,
                  columnToSortBy: "username",
                  hidden: true
                },
                {
                  heading: () => "Current Company",
                  cell: a => a.company_user[0]?.company.name,
                  columnToSortBy: "company_user"
                }
              ]}
            />
          )}
        </Card>
      </ListContainer>
    </ListPageContent>
  );
};

export default ClientUsers;
