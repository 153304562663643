import React, { Suspense, useState } from "react";
import { ResumeSearchInput } from "./ResumeSearchInput";
import { SuspenseFallback } from "./SuspenseFallback";

const ResumeSearchResults = React.lazy(() => import("./ResumeSearchResults"));

export type DeveloperType = {
  developerId: string;
  firstName: string;
  lastName: string;
  matchText: string;
  resumeUrl: string;
  status: string;
  email: string;
  country: string;
  tosSignedOn: string;
  billableRate: string;
  costRate: string;
  mostRecentApplicationDate: string;
  avgCostRate: string;
  developerAssessments: string;
};

export function ResumeSearchContainer() {
  const [searchData, setSearchData] = useState({} as { result: DeveloperType[]; error?: "" });
  const isSearchEmpty = searchData.result?.length === 0;
  const isErrorResult = !!searchData.error;

  return (
    <>
      <ResumeSearchInput setSearchData={setSearchData} />
      <Suspense fallback={<SuspenseFallback />}>
        <ResumeSearchResults searchData={searchData.result} />
      </Suspense>
      {isSearchEmpty && <p>No search results</p>}
      {isErrorResult && <p style={{ color: "red" }}>{searchData.error}</p>}
    </>
  );
}
