import React from "react";

import { LogoPulseLoader } from "./LogoPulseLoader";
import { useSuspenseLoading } from "../hooks/useSuspenseLoading";

export const LoadingLogoWithTitle = ({ title, isLoading = false }: { title: string; isLoading: boolean }) => {
  const shouldShowLoading = useSuspenseLoading(isLoading, 600);

  if (!shouldShowLoading) return null;

  return (
    <div
      style={{
        position: "absolute",
        width: 70,
        bottom: 20,
        right: 40,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <LogoPulseLoader />
      <p style={{ textAlign: "center", fontSize: 10 }}>{title}</p>
    </div>
  );
};
