import { ComboboxOption as ReachComboboxOption } from "@reach/combobox";
import { MenuItem } from "@reach/menu-button";

import { styled } from "../util/styled";
import { Theme } from "../util/theme";

const optionStyles = ({ theme }: { theme: Theme }) => ({
  padding: `${theme.space / 2}px ${theme.space}px`,
  borderBottom: `1px solid ${theme.colors.lightBorder}`,
  background: "white",
  cursor: "pointer",

  [`:hover,
    :focus,
    &[data-highlighted]`]: {
    background: theme.colors.lightBackground
  }
});

export const ComboboxOption = styled(ReachComboboxOption)(optionStyles);
export const MenuOption = styled(MenuItem)(optionStyles);
