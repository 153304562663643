import React, { FC, useState, useCallback, useContext, useMemo, useEffect } from "react";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";

import { Modal, ModalContent, ModalHeader, ModalActionsContainer } from "./Modal";
import { getClientUsers, getCompanies, upsertClients } from "../queries";
import { Input } from "./Input";
import { Button } from "./Button";
import { AddClientForm, ErrorMessage } from "./AddClientModal.styled";

import { ModalsContext } from "../contexts/ModalsContext";
import { Label } from "./Label";
import { getCompaniesQuery } from "../queries/getCompanies";
import { upsertClientsMutation } from "../mutations/upsertClients";
import ErrorToast from "./ErrorToast";
import { useMutation, useQuery } from "@apollo/client";

const MultiSelect = React.lazy(/*webpackChunkName:"MultiSelect"*/ () => import("./Multiselect"));

const AddClientModal: FC<{
  refetch: (variables?: OperationVariables | undefined) => Promise<ApolloQueryResult<getClientUsers>>;
}> = ({ refetch }) => {
  const { checkIfOpen, closeModal } = useContext(ModalsContext);
  const [selected, setSelected] = useState<{ value: string; label: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const isOpen = checkIfOpen("Add Client");

  const closeAddClientModal = () => closeModal("Add Client");

  useEffect(() => {
    if (selected.length > 1) {
      setSelected([selected[selected.length - 1]]);
    }
  }, [selected]);

  const [newClient, setNewClient] = useState({
    display_name: "",
    email: "",
    username: ""
  });

  const { data } = useQuery<getCompanies>(getCompaniesQuery);

  const options = useMemo(() => data?.companies.map(c => ({ value: c.id as string, label: c.name! })), [data]);

  const [createClient, { loading, error }] = useMutation<upsertClients>(upsertClientsMutation, {
    variables: { ...newClient, company_id: selected[0]?.value }
  });

  const updateNewClientField = useCallback(
    (fieldName: keyof typeof newClient) => (value: typeof newClient[typeof fieldName]) =>
      setNewClient(prev => ({
        ...prev,
        [fieldName]: fieldName === "email" ? value.toLowerCase().trim() : value
      })),
    [newClient]
  );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={closeAddClientModal}
      aria-label="Create New Developer Profile"
      style={{ overflow: "unset" }}
    >
      <ErrorToast error={error} />
      <ModalHeader close={closeAddClientModal}>Add New Client</ModalHeader>
      <ModalContent>
        <AddClientForm
          action="#"
          onSubmit={e => {
            e.preventDefault();

            if (selected.length === 0) {
              setErrorMessage("Please select a company to continue");
              return;
            }

            createClient().then(response => {
              closeAddClientModal();
              refetch();
              setSelected([]);
              setNewClient({ display_name: "", email: "", username: "" });
            });
          }}
        >
          <Input
            autoFocus
            required
            style={{ gridArea: "name" }}
            typeOfInput="regular"
            label="Full Name (required)"
            type="text"
            placeholder="Lee Smith"
            value={newClient.display_name}
            onChange={e => updateNewClientField("display_name")(e.target.value)}
          />
          <Input
            required
            style={{ gridArea: "email" }}
            typeOfInput="regular"
            label="Email (required)"
            type="email"
            placeholder="lee_smith@g2i.co"
            value={newClient.email}
            onChange={e => updateNewClientField("email")(e.target.value?.trim())}
          />
          <div style={{ gridArea: "stage" }}>
            <Label fullWidth style={{ marginBottom: ".5rem", display: "flex", gap: 0 }}>
              <span>Companies (required)</span>
            </Label>
            {options && (
              <MultiSelect selected={selected} setSelected={setSelected} data={options} hasSelectAll={false} />
            )}
          </div>
          <ModalActionsContainer>
            <Button htmlButtonType="submit" disabled={loading} color="primary" type="regular" loading={loading}>
              Create New Client
            </Button>
            <Button htmlButtonType="button" disabled={loading} onClick={closeAddClientModal} type="regular">
              Cancel
            </Button>
          </ModalActionsContainer>
          {errorMessage && selected.length === 0 && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </AddClientForm>
      </ModalContent>
    </Modal>
  );
};

export default AddClientModal;
