import { getUrlFromHandle } from "./getUrlFromHandle";

export const getAvatarUrl = (developer: { url_avatar: string | null; url_github: string | null }) => {
  if (developer.url_avatar) {
    /* parameters after the "?" are ignored by cloudinary
     * the solution is to escape the '?' special character (by replacing it with '%3F')
     * https://cloudinary.com/documentation/image_transformations#removing_or_changing_the_background
     */
    return developer.url_avatar?.replace("?", "%3F");
  }

  const getUsername = (url: string) =>
    getUrlFromHandle(url)
      .split("/")
      .slice(-1)
      .join("")
      .split("?")[0];

  if (developer.url_github) {
    const gitHubUserName = getUsername(developer.url_github);
    return gitHubUserName ? `https://github.com/${gitHubUserName}.png` : undefined;
  }
};
