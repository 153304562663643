import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { authorizationParams } from "../constants";

export interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory = ({ children }: Auth0ProviderWithHistoryProps): React.ReactElement => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  if (!domain || !clientId)
    throw new Error("Please set REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID env. variables");

  const history = useHistory();

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = appState => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
      useRefreshTokensFallback
      leeway={120}
      authorizationParams={{
        ...authorizationParams,
        redirect_uri: window.location.origin
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
