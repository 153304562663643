import { ComboboxInput as ReachComboboxInput } from "@reach/combobox";
import chroma from "chroma-js";

import { InputProps } from "./Input";
import { styled } from "../util/styled";
import { Theme } from "../util/theme";

export const inputStyles = ({ theme }: { theme: Theme }) =>
  ({
    padding: `${theme.space / 2}px ${theme.space}px`,
    borderRadius: 6,
    border: `1px solid ${theme.colors.lightBorder}`,
    font: "inherit",
    fontSize: "1.2rem",
    borderColor: theme.colors.border,
    fontWeight: 300,
    letterSpacing: 1,
    height: 50,
    background: theme.colors.white,

    "::placeholder": {
      color: "#aaa"
    } as const,

    ":focus": {
      outline: "none",
      borderColor: theme.colors.primary,
      boxShadow: `0 0 0 4px
    ${chroma(theme.colors.primary)
      .alpha(0.5)
      .hex()}`
    } as const
  } as const);

export const InputContainer = styled.div<{ hasIcon: boolean }>(({ theme, hasIcon }) => {
  return {
    position: "relative",

    input: {
      paddingRight: hasIcon ? 36 : theme.space / 2,
      width: "100%"
    }
  };
});

export const IconContainer = styled.div(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  right: theme.space / 2
}));

export const ComboboxInput = styled(ReachComboboxInput, {
  shouldForwardProp: prop => prop !== "fullWidth"
})<Pick<InputProps<HTMLInputElement>, "fullWidth">>(inputStyles);

export const InputElement = styled("input")(inputStyles);
