import { addProtocol } from "./addProtocol";

export const getUrlFromHandle = (handleOrUrl: string) => {
  if (handleOrUrl.startsWith("@")) {
    return `https://twitter.com/${handleOrUrl.slice(1)}`;
  }
  if (handleOrUrl.startsWith("twitter")) {
    return addProtocol(handleOrUrl);
  }
  return handleOrUrl;
};
