import React from "react";
import { Card } from "../components/Card";
import { ListPageContent } from "../components/ListPageContent";
import { styled } from "../util/styled";
import { SkillsSearchContainer } from "../components/SkillsSearchContainer";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SkillsSearch = () => {
  return (
    <div>
      <SkillsSearchContainer />
    </div>
  );
};

export default SkillsSearch;
