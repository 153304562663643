import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router";

import { Layout } from "./Layout";
import { Sidenav } from "./Sidenav";
import { Header } from "./Header";
import { Content } from "./Content";
import { Breadcrumb } from "./Breadcrumb";
import { CurrentUserContext } from "../contexts/CurrentUser";
import { ModalSuspenseFallback } from "./ModalSuspenseFallback";
import { SuspenseFallback } from "./SuspenseFallback";
import { ToastProvider } from "./ToastProvider";
import { ModalsProvider, ModalsContext } from "../contexts/ModalsContext";
import ClientUsers from "../pages/ClientUsers";
import { useAuth0 } from "@auth0/auth0-react";
import { ApolloProviderWithClient } from "./ApolloProviderWithClient";
import { AuthenticationGuard } from "../AuthenticationGuard";
import ResumeSearch from "../pages/ResumeSearch";
import SkillsSearch from "../pages/SkillsSearch";

const Skills = lazy(() => import(/* webpackChunkName: "developers-list"*/ "../pages/Skills"));
const SearchDevelopers = lazy(() => import(/* webpackChunkName: "search-modal" */ "./SearchDevelopers"));
const Developers = lazy(() => import(/* webpackChunkName: "developers-list"*/ "../pages/Developers"));
const Applicants = lazy(() => import(/* webpackChunkName: "candidate-developers-list"*/ "../pages/Applicants"));
const VettingDevelopers = lazy(() =>
  import(/* webpackChunkName: "vetting-developers-list"*/ "../pages/VettingDevelopers")
);

const LatestDevEvents = lazy(() => import(/* webpackChunkName: "vetting-developers-events"*/ "./LatestDevEvents"));

const LatestAssessmentEvents = lazy(() =>
  import(/* webpackChunkName: "vetting-assessment-events"*/ "./LatestAssessmentEvents")
);

const DevelopersInVetting = lazy(() => import(/* webpackChunkName: "vetting-developers"*/ "./DevelopersInVetting"));

const Developer = lazy(() => import(/* webpackChunkName: "developer-detail-page" */ "../pages/Developer"));
const AddDeveloperModal = lazy(() => import(/* webpackChunkName: "add-developer-modal"*/ "./AddDeveloperModal"));

const Jobs = lazy(() => import(/* webpackChunkName: "jobs"*/ "../pages/Jobs"));
const AddJobModal = lazy(() => import(/* webpackChunkName: "add-job-modal"*/ "./AddJobModal"));
const EditJobModal = lazy(() => import(/* webpackChunkName: "edit-job-modal"*/ "./EditJobModal"));

const CheckIn = lazy(() => import(/* webpackChunkName: "check-in"*/ "../pages/CheckIn"));
const AddCheckInModal = lazy(() => import(/* webpackChunkName: "add-check-in-modal"*/ "./CheckInModal"));

const Metrics = lazy(() => import(/* webpackChunkName: "metrics"*/ "../pages/Metrics"));
const Kpis = lazy(() => import(/* webpackChunkName: "kpis"*/ "../pages/Kpis"));

const DeveloperCheckInTimeline = lazy(() =>
  import(/* webpackChunkName: "check-in-timeline"*/ "../pages/DeveloperCheckInTimeline")
);
const DSTMs = lazy(() => import(/* webpackChunkName: "dstms"*/ "../pages/DSTMs"));
const Companies = lazy(() => import(/* webpackChunkName: "companies"*/ "../pages/Companies"));
const AddCompanyModal = lazy(() => import(/* webpackChunkName: "add-company-modal"*/ "./AddCompanyModal"));
const EditCompanyModal = lazy(() => import(/* webpackChunkName: "edit-company-modal"*/ "./EditCompanyModal"));
const MyAssessments = lazy(() => import(/* webpackChunkName: "my-assessments"*/ "../pages/MyAssessments"));
const Profiles = lazy(() => import(/* webpackChunkName: "profiles"*/ "../pages/Profiles"));
const VettingOverview = lazy(() => import(/* webpackChunkName: "vetting-overview"*/ "../pages/VettingOverview"));
const AssessmentsCenter = lazy(() => import(/* webpackChunkName: "assessments-center"*/ "../pages/AssessmentsCenter"));
const ChallengesCenter = lazy(() => import(/* webpackChunkName: "challenges-center"*/ "../pages/ChallengesCenter"));
const Meetings = lazy(() => import(/* webpackChunkName: "meetings"*/ "../pages/Meetings"));
const AddJobProfileNoteModal = lazy(() =>
  import(/* webpackChunkName: "add-job-profile-note-modal"*/ "./AddJobProfileNoteModal")
);

const AddJobProfileModal = lazy(() => import(/* webpackChunkName: "add-job-profile-modal" */ "./AddJobProfileModal"));
const EditJobProfileModal = lazy(() =>
  import(/* webpackChunkName: "edit-job-profile-modal "*/ "./EditJobProfileModal")
);

const AuthedLatestDevEvents = () => <AuthenticationGuard component={<LatestDevEvents />} />;
const AuthedLatestAssessmentEvents = () => <AuthenticationGuard component={<LatestAssessmentEvents />} />;
const AuthedDevelopersInVetting = () => <AuthenticationGuard component={<DevelopersInVetting />} />;

const App: React.FC = () => {
  const { user } = useAuth0();
  const currentUser = user?.["https://g2i.co/jwt/claims"];

  return (
    <ToastProvider>
      <ApolloProviderWithClient>
        <CurrentUserContext.Provider
          value={{
            id: currentUser?.id,
            username: user?.nickname!,
            displayName: user?.name!,
            role: currentUser?.role
          }}
        >
          <ModalsProvider>
            <Layout>
              <Sidenav />
              <Header>
                <Breadcrumb />
              </Header>
              <Content>
                <Suspense fallback={<ModalSuspenseFallback />}>
                  <ModalsContext.Consumer>
                    {({ checkIfOpen }) => (
                      <>
                        {checkIfOpen("Add Developer") && <AddDeveloperModal />}
                        {checkIfOpen("Search Developers") && <SearchDevelopers />}
                      </>
                    )}
                  </ModalsContext.Consumer>
                </Suspense>
                <Suspense fallback={<SuspenseFallback />}>
                  <Switch>
                    <Route
                      path="/my-assessments"
                      render={() => <AuthenticationGuard component={<MyAssessments />} />}
                    />
                    <Route
                      path="/developer/:developerId/assessments/:developerAssessmentId"
                      render={routeProps => (
                        <AuthenticationGuard
                          component={
                            <Developer
                              activeTab="assessment"
                              developerAssessmentId={routeProps.match.params.developerAssessmentId}
                              id={routeProps.match.params.developerId}
                            />
                          }
                        />
                      )}
                    />
                    {currentUser?.role === "reviewer" ? (
                      <>
                        <Route
                          path="/"
                          render={() => <AuthenticationGuard component={<Redirect to="/my-assessments" />} />}
                        />
                      </>
                    ) : (
                      <Switch>
                        <Route
                          path="/vetting-overview"
                          render={() => <AuthenticationGuard component={<VettingOverview />} />}
                        />
                        <Route
                          path="/assessments/:assessmentId?"
                          render={() => <AuthenticationGuard component={<AssessmentsCenter />} />}
                        />
                        <Route
                          path="/challenges/:challengeId?"
                          render={() => <AuthenticationGuard component={<ChallengesCenter />} />}
                        />
                        <Route
                          path="/check-in/timeline/new/"
                          render={({ history, location }) => (
                            <>
                              <AddCheckInModal close={() => history.push("/check-in/timeline")} />
                              <CheckIn key={location.search} />
                            </>
                          )}
                        />
                        <Route
                          path="/check-in/timeline"
                          render={() => <AuthenticationGuard component={<DeveloperCheckInTimeline />} />}
                        />
                        <Route
                          path="/check-in/new/:developerId"
                          render={({ history, match, location }) => (
                            <>
                              <AddCheckInModal
                                close={() => history.push("/check-in")}
                                developerId={match.params.developerId}
                              />
                              <CheckIn key={location.search} />
                            </>
                          )}
                        />
                        <Route
                          path="/check-in/timeline"
                          render={() => <AuthenticationGuard component={<DeveloperCheckInTimeline />} />}
                        />
                        <Route
                          path="/check-in"
                          render={routeProps => (
                            <AuthenticationGuard component={<CheckIn key={routeProps.location.search} />} />
                          )}
                        />
                        <Route path="/metrics" render={() => <AuthenticationGuard component={<Metrics />} />} />
                        <Route path="/kpis" render={() => <AuthenticationGuard component={<Kpis />} />} />

                        <Route
                          path="/companies/new"
                          render={({ history }) => (
                            <AuthenticationGuard
                              component={
                                <>
                                  <AddCompanyModal close={() => history.push("/companies")} />
                                  <Companies />
                                </>
                              }
                            />
                          )}
                        />
                        <Route
                          path="/companies/:companyId/job-profiles"
                          render={({ history, match }) => (
                            <AuthenticationGuard
                              component={
                                <>
                                  <EditCompanyModal
                                    tab="job-profiles"
                                    existingCompanyId={match.params.companyId}
                                    close={() => history.push("/companies")}
                                  />
                                  <Companies />
                                </>
                              }
                            />
                          )}
                        />
                        <Route
                          path="/companies/:companyId/jobs"
                          render={({ history, match }) => (
                            <AuthenticationGuard
                              component={
                                <>
                                  <EditCompanyModal
                                    tab="jobs"
                                    existingCompanyId={match.params.companyId}
                                    close={() => history.push("/companies")}
                                  />
                                  <Companies />
                                </>
                              }
                            />
                          )}
                        />
                        <Route
                          path="/companies/:companyId/overview"
                          render={({ history, match }) => (
                            <AuthenticationGuard
                              component={
                                <>
                                  <EditCompanyModal
                                    tab="overview"
                                    existingCompanyId={match.params.companyId}
                                    close={() => history.push("/companies")}
                                  />
                                  <Companies />
                                </>
                              }
                            />
                          )}
                        />
                        <Route
                          path="/companies/:companyId"
                          render={({ match }) => <Redirect to={`/companies/${match.params.companyId}/overview`} />}
                        />
                        <Route
                          path="/companies"
                          render={() => (
                            <AuthenticationGuard component={<AuthenticationGuard component={<Companies />} />} />
                          )}
                        />
                        <Route path="/dstms" render={() => <AuthenticationGuard component={<DSTMs />} />} />
                        <Route path="/jobs">
                          <AuthenticationGuard component={<Jobs />} />
                          <Route
                            path="/jobs/:jobId/new"
                            render={({ history }) => (
                              <AuthenticationGuard component={<AddJobModal close={() => history.push("/jobs")} />} />
                            )}
                          />
                          <Route
                            path="/jobs/:jobId/overview"
                            render={({ history, match }) => (
                              <AuthenticationGuard
                                component={
                                  <EditJobModal
                                    tab="overview"
                                    existingJobId={match.params.jobId}
                                    close={() => history.push("/jobs")}
                                  />
                                }
                              />
                            )}
                          />

                          <Route
                            path="/jobs/:jobId/countries"
                            render={({ history, match }) => (
                              <AuthenticationGuard
                                component={
                                  <EditJobModal
                                    tab="countries"
                                    existingJobId={match.params.jobId}
                                    close={() => history.push("/jobs")}
                                  />
                                }
                              />
                            )}
                          />

                          <Route
                            path="/jobs/:jobId/notes"
                            render={({ history, match }) => (
                              <AuthenticationGuard
                                component={
                                  <EditJobModal
                                    tab="notes"
                                    existingJobId={match.params.jobId}
                                    close={() => history.push("/jobs")}
                                  />
                                }
                              />
                            )}
                          />

                          <Route
                            path="/jobs/:jobId/job-profiles"
                            render={({ history, match }) => (
                              <AuthenticationGuard
                                component={
                                  <EditJobModal
                                    tab="job-profiles"
                                    existingJobId={match.params.jobId}
                                    close={() => history.push("/jobs")}
                                  />
                                }
                              />
                            )}
                          />
                        </Route>

                        <Route
                          path="/vetting"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={
                                <>
                                  <VettingDevelopers key={routeProps.location.search}>
                                    <Switch>
                                      <Route path="/vetting/developer_events" component={AuthedLatestDevEvents} />
                                      <Route
                                        path="/vetting/assessment_events"
                                        component={AuthedLatestAssessmentEvents}
                                      />
                                      <Route path="/" component={AuthedDevelopersInVetting} />
                                    </Switch>
                                  </VettingDevelopers>
                                </>
                              }
                            />
                          )}
                        />
                        <Route
                          path="/developers"
                          render={routeProps => (
                            <AuthenticationGuard component={<Developers key={routeProps.location.search} />} />
                          )}
                        />
                        <Route
                          path="/resume-search"
                          render={routeProps => <AuthenticationGuard component={<ResumeSearch />} />}
                        />
                        <Route
                          path="/skills-search/:jobId?"
                          render={routeProps => <AuthenticationGuard component={<SkillsSearch />} />}
                        />
                        <Route path="/skills" render={() => <AuthenticationGuard component={<Skills />} />} />
                        <Route path="/meetings" render={() => <AuthenticationGuard component={<Meetings />} />} />
                        <Route
                          path="/applicants"
                          render={routeProps => (
                            <AuthenticationGuard component={<Applicants key={routeProps.location.search} />} />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/details"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Developer activeTab="details" id={routeProps.match.params.developerId} />}
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/assessments"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Developer activeTab="assessments" id={routeProps.match.params.developerId} />}
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/meetings"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Developer activeTab="meetings" id={routeProps.match.params.developerId} />}
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/resume"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Developer activeTab="resume" id={routeProps.match.params.developerId} />}
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/communication"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={
                                <Developer activeTab="communication" id={routeProps.match.params.developerId} />
                              }
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/audit"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Developer activeTab="audit" id={routeProps.match.params.developerId} />}
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/skills"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Developer activeTab="skills" id={routeProps.match.params.developerId} />}
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/applications/:applicationId"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={
                                <Developer
                                  activeTab="application"
                                  id={routeProps.match.params.developerId}
                                  applicationId={routeProps.match.params.applicationId}
                                />
                              }
                            />
                          )}
                        />
                        <Route
                          path="/developer/:developerId/applications"
                          render={routeProps => (
                            <Developer activeTab="applications" id={routeProps.match.params.developerId} />
                          )}
                        />

                        <Route
                          path="/developer/:developerId/overview"
                          render={routeProps => (
                            <>
                              <AuthenticationGuard
                                component={
                                  <Developer
                                    key={routeProps.match.params.developerId}
                                    activeTab="overview"
                                    id={routeProps.match.params.developerId}
                                  />
                                }
                              />

                              <Suspense fallback={<ModalSuspenseFallback />}>
                                <Route
                                  path="/developer/:developerId/overview/:jobProfileId/notes"
                                  render={({ match, history }) => (
                                    <AuthenticationGuard
                                      component={
                                        <AddJobProfileNoteModal
                                          close={() => history.push(`/developer/${match.params.developerId}/overview`)}
                                          jobProfileId={match.params.jobProfileId}
                                        />
                                      }
                                    />
                                  )}
                                />
                                <Route
                                  path="/developer/:developerId/overview/jobProfile/new"
                                  render={({
                                    match: {
                                      params: { developerId }
                                    },
                                    history
                                  }) => (
                                    <AuthenticationGuard
                                      component={
                                        <AddJobProfileModal
                                          close={() => history.push(`/developer/${developerId}/overview`)}
                                          developerId={developerId}
                                        />
                                      }
                                    />
                                  )}
                                />
                                <Route
                                  path="/developer/:developerId/overview/:jobProfileId/edit"
                                  render={({
                                    match: {
                                      params: { jobProfileId, developerId }
                                    },
                                    history
                                  }) => (
                                    <AuthenticationGuard
                                      component={
                                        <EditJobProfileModal
                                          close={() => history.push(`/developer/${developerId}/overview`)}
                                          developerId={developerId}
                                          existingJobProfileId={jobProfileId}
                                          currentUserId={currentUser?.id}
                                        />
                                      }
                                    />
                                  )}
                                />
                              </Suspense>
                            </>
                          )}
                        />

                        <Route
                          path="/developer/:developerId/"
                          render={routeProps => (
                            <AuthenticationGuard
                              component={<Redirect to={`/developer/${routeProps.match.params.developerId}/overview`} />}
                            />
                          )}
                        />

                        <Route path="/profiles" render={() => <AuthenticationGuard component={<Profiles />} />} />
                        {currentUser?.role === "admin" && (
                          <Route path="/clients" render={() => <AuthenticationGuard component={<ClientUsers />} />} />
                        )}
                        <Route
                          exact
                          path="/"
                          render={() => <AuthenticationGuard component={<Redirect to="/vetting" />} />}
                        />
                      </Switch>
                    )}
                  </Switch>
                </Suspense>
              </Content>
            </Layout>
          </ModalsProvider>
        </CurrentUserContext.Provider>
      </ApolloProviderWithClient>
    </ToastProvider>
  );
};

export default App;
