import { HTMLAttributes } from "react";

import { styled } from "../util/styled";

export const Container = styled.table<{ condensed: boolean }>(({ theme, condensed }) => ({
  width: "100%",
  borderCollapse: "collapse",
  fontSize: condensed ? ".8rem" : "inherit"
}));

export const Thead = styled.thead`
  text-align: left;
  text-transform: uppercase;
  font-size: 0.7rem;
`;

export const Th = styled.th<{ hasSort?: boolean }>`
  padding: ${({ theme }) => theme.space / 2}px;
  &:hover {
    color: ${({ hasSort, theme }) => hasSort && theme.colors.lighterText};
  }
`;

export const Tr = styled.tr<{ emptyRow: boolean } & HTMLAttributes<HTMLTableRowElement>>(
  ({ theme, onClick, emptyRow }) => ({
    borderBottom: "1px solid",
    borderColor: "#0001",
    height: emptyRow ? "120px" : "auto",
    textAlign: emptyRow ? "center" : "left",
    cursor: onClick ? "pointer" : "initial",
    ":hover": { backgroundColor: onClick ? theme.colors.lightBackground : "transparent" }
  })
);

export const Tbody = styled.tbody``;

export const Td = styled.td`
  padding: ${({ theme }) => theme.space / 2}px;
`;
