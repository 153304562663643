import React, { FC } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Container, Link, Separator } from "./Breadcrumb.styled";

type BreadcrumbProps = {};

export const Breadcrumb: FC<BreadcrumbProps> = () => {
  const { pathname } = useLocation();
  const { push, goBack } = useHistory();

  return (
    <Container>
      <Link onClick={goBack}>&laquo;</Link>
      <Link onClick={() => push("/")}>Home</Link>

      {pathname.includes("/developer/") && (
        <>
          <Separator>/</Separator>
          <Link active>Developer Profile</Link>
        </>
      )}
      {pathname.includes("/application-details") && (
        <>
          <Separator>/</Separator>
          <Link active>Applications</Link>
        </>
      )}
      {pathname.includes("/developers") && (
        <>
          <Separator>/</Separator>
          <Link active>Developer List</Link>
        </>
      )}
      {pathname.includes("/companies") && (
        <>
          <Separator>/</Separator>
          <Link active>Companies</Link>
        </>
      )}
      {pathname.includes("/jobs") && (
        <>
          <Separator>/</Separator>
          <Link active>Jobs</Link>
        </>
      )}
      {pathname.includes("/metrics") && (
        <>
          <Separator>/</Separator>
          <Link active>Metrics</Link>
        </>
      )}
    </Container>
  );
};
