import { styled } from "../util/styled";

export const Container = styled("div", { shouldForwardProp: p => p !== "active" })<{ active: boolean }>`
  font-size: 0.8rem;
  display: grid;
  align-items: center;
  grid-template-columns: 16px auto;
  gap: ${({ theme }) => theme.space / 2}px;
  margin: 0;
  padding: ${({ theme }) => theme.space / 2}px ${({ theme }) => theme.space}px;
  border-radius: 2px;
  font-weight: ${({ active }) => (active ? "bold" : "400")};
  color: #fffc;
  cursor: pointer;
  background: ${({ active }) => (active ? "#0005" : "transparent")};

  :hover,
  :focus {
    outline: none;
    background: #0005;
    color: #ffff;
  }
`;

Container.defaultProps = { role: "button", tabIndex: 0 };

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelContainer = styled.div``;
