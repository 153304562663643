export const CLOUDINARY_URL = "https://res.cloudinary.com/deeworisl/image/fetch";

export const CLOUDINARY_OPTIONS = {
  AVATAR_LG: "/c_scale,h_350,q_auto:good"
};

/**
 *
 * @param url Starts with "/"
 * @param optionString
 */

export const getCloudinaryUrl = (url?: string | null, optionString?: string) =>
  url ? `${CLOUDINARY_URL}${optionString || ""}/${url}` : undefined;

export default getCloudinaryUrl;
