import { css } from "@emotion/core";
import { MouseEvent } from "react";

import { styled } from "../util/styled";
import { mapStringToColors } from "../util/color";

export const Container = styled("figure", {
  shouldForwardProp: prop => !["hasName", "size", "blurHover"].includes(prop)
})<{
  hasName: boolean;
  size: number;
  blurHover?: boolean;
  onClick?: (e: MouseEvent) => void;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ hasName, size }) => (hasName ? `${size}px auto` : `${size}px`)};
  gap: ${({ theme }) => theme.space / 2}px;
  margin: 0;

  figcaption {
    text-decoration: ${({ onClick }) => (onClick ? "underline" : "none")};
  }

  ${({ blurHover }) =>
    blurHover &&
    css`
      opacity: 1;
      transition: 0.2s ease-in-out;

      &:hover {
        opacity: 0.7;
      }
    `}
`;

export const ImageContainer = styled("div", {
  shouldForwardProp: prop => !["name", "size", "url"].includes(prop)
})<{
  name: string;
  size: number;
  url: string | undefined;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => size / 2}px;
  font-weight: bold;
  overflow: hidden;
  background-color: ${({ url, name, theme }) => (url ? "transparent" : mapStringToColors(theme.palette)(name))};
  color: white;
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
`;

export const LabelContainer = styled.figcaption`
  display: grid;
  grid-template-rows: min-content auto;
  gap: ${({ theme }) => theme.space / 8}px;
`;

export const ContentContainer = styled.span`
  font-size: 0.8rem;
  align-self: flex-start;
`;

export const HoverOverlay = styled("div", { shouldForwardProp: prop => !["visible"].includes(prop) })<{
  visible: boolean;
}>`
  ${({ visible }) =>
    visible &&
    css`
      position: absolute;
      display: flex;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    `}
`;
