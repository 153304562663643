import { styled } from "../util/styled";

export const Label = styled("label")<{ fullWidth?: boolean; hasText?: boolean; maxHeight?: string }>(
  ({ theme, fullWidth, hasText, maxHeight }) => ({
    fontSize: "0.85rem",
    color: theme.colors.lightText,
    fontWeight: 500,
    display: "grid",
    width: fullWidth ? "100%" : "auto",
    maxHeight: maxHeight || "40vh",
    gridTemplateRows: hasText ? "min-content auto" : "min-content",
    gap: theme.space / 2
  })
);
