import React, { FC, useState, useCallback, useContext, useMemo, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ApolloQueryResult, OperationVariables } from "@apollo/client";

import { Modal, ModalContent, ModalHeader, ModalActionsContainer } from "./Modal";
import { getClientUsers, getClientUsers_users, getCompanies, upsertClients } from "../queries";
import { Input } from "./Input";
import { Button } from "./Button";
import { AddClientForm } from "./AddClientModal.styled";

import { ModalsContext } from "../contexts/ModalsContext";
import { Label } from "./Label";
import { useHistory } from "react-router-dom";
import { getCompaniesQuery } from "../queries/getCompanies";
import { upsertClientsMutation } from "../mutations/upsertClients";
import ErrorToast from "./ErrorToast";

const MultiSelect = React.lazy(/*webpackChunkName:"MultiSelect"*/ () => import("./Multiselect"));

const EditClientModal: FC<{
  refetch: (variables?: OperationVariables | undefined) => Promise<ApolloQueryResult<getClientUsers>>;
  editModalData: getClientUsers_users | null;
}> = ({ refetch, editModalData }) => {
  const { checkIfOpen, closeModal } = useContext(ModalsContext);
  const [selected, setSelected] = useState<{ value: string; label: string }[]>([]);
  const { push } = useHistory();

  const isOpen = checkIfOpen("Edit Client");

  const closeAddClientModal = () => {
    push("/clients");
    closeModal("Edit Client");
  };

  useEffect(() => {
    if (selected.length > 1) {
      setSelected([selected[selected.length - 1]]);
    }
  }, [selected]);

  const [updatedClient, setUpdatedClient] = useState({
    display_name: "",
    email: "",
    username: ""
  });

  useEffect(() => {
    setUpdatedClient({
      display_name: editModalData?.display_name || "",
      email: editModalData?.email || "",
      username: editModalData?.username || ""
    });
    setSelected(editModalData?.company_user.map(({ company }) => ({ value: company.id, label: company.name! })) || []);
  }, [editModalData]);

  const { data } = useQuery<getCompanies>(getCompaniesQuery);

  const options = useMemo(() => data?.companies.map(c => ({ value: c.id as string, label: c.name! })), [data]);

  const [updateClient, { loading, error }] = useMutation<upsertClients>(upsertClientsMutation, {
    variables: { ...updatedClient, company_id: selected[0]?.value }
  });

  const updateNewClientField = useCallback(
    (fieldName: keyof typeof updatedClient) => (value: typeof updatedClient[typeof fieldName]) =>
      setUpdatedClient(prev => ({
        ...prev,
        [fieldName]: fieldName === "email" ? value.toLowerCase().trim() : value.trim()
      })),
    [updatedClient]
  );

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={closeAddClientModal}
      aria-label="Create New Developer Profile"
      style={{ overflow: "unset" }}
    >
      {<ErrorToast error={error} />}
      <ModalHeader close={closeAddClientModal}>Update Client</ModalHeader>
      <ModalContent>
        <AddClientForm
          action="#"
          onSubmit={e => {
            e.preventDefault();
            updateClient().then(response => {
              closeAddClientModal();
              refetch();
              setSelected([]);
              setUpdatedClient({ display_name: "", email: "", username: "" });
            });
          }}
        >
          <Input
            autoFocus
            required
            style={{ gridArea: "name" }}
            typeOfInput="regular"
            label="Full Name"
            type="text"
            placeholder="Lee Smith"
            value={updatedClient.display_name}
            onChange={e => updateNewClientField("display_name")(e.target.value)}
          />
          <Input
            required
            style={{ gridArea: "email" }}
            typeOfInput="regular"
            label="Email"
            type="email"
            placeholder="lee_smith@g2i.co"
            value={updatedClient.email}
            onChange={e => updateNewClientField("email")(e.target.value)}
          />
          <Input
            style={{ gridArea: "username" }}
            typeOfInput="regular"
            label="Username"
            type="text"
            placeholder="leesmith"
            value={updatedClient.username}
            onChange={e => updateNewClientField("username")(e.target.value)}
          />
          <div style={{ gridArea: "stage" }}>
            <Label fullWidth style={{ marginBottom: ".5rem" }}>
              Companies
            </Label>
            {options && (
              <MultiSelect selected={selected} setSelected={setSelected} data={options} hasSelectAll={false} />
            )}
          </div>
          <ModalActionsContainer>
            <Button htmlButtonType="submit" disabled={loading} color="primary" type="regular" loading={loading}>
              Update Client
            </Button>
            <Button htmlButtonType="button" disabled={loading} onClick={closeAddClientModal} type="regular">
              Cancel
            </Button>
          </ModalActionsContainer>
        </AddClientForm>
      </ModalContent>
    </Modal>
  );
};

export default EditClientModal;
