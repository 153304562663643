import { gql } from "@apollo/client";

export const getCompaniesQuery = gql`
  query getCompanies {
    companies(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
      id
      name
    }
  }
`;
